import { Api } from "./Api";
import { FullRequestParams } from "./http-client";
import { RefreshToken } from "./RefreshToken";

const securityWorker = (accessToken: string) => ({
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})
/** The global instance of the api. 
 * The baseUrl will be set in the loadConfig thunk when the app mounts.
 * The security token will be set below if it already exists in session storage. The Auth component sets the token to session storage.
 * Before the app mounts you will not be able to use this. */
 export const apiInstance = new Api<string>({
  baseUrl: null,
  securityWorker,
});
// Refresh token handling
const oldRequest = apiInstance.request;
apiInstance.request = async <T = any>(params: FullRequestParams): Promise<T> => {
  try {
    return await oldRequest(params);
  } catch (error: any) {
    if (error.status === 401) {
      // Initialize a refresh token client and get a new access token
      const refreshApi = new RefreshToken({
        baseUrl: apiInstance.baseUrl,
        securityWorker,
      });
      refreshApi.setSecurityData(sessionStorage.getItem("refresh_token"));

      const refreshTokenData = await refreshApi.authGetRefreshToken();
      sessionStorage.setItem("access_token", refreshTokenData.accessToken);
      sessionStorage.setItem("refresh_token", refreshTokenData.refreshToken);
      apiInstance.setSecurityData(refreshTokenData.accessToken);
      return await oldRequest(params);
    } else {
      throw error;
    }
  }
};

// Set the auth header if available.
// If the auth token is not available at this point the app must be in the process of
// getting it's auth credentials, in which case the auth component will set the security data of the api instance
const token = sessionStorage.getItem("access_token");
if (token) {
  apiInstance.setSecurityData(token);
}