/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Patient,
  ReportBipolePatient,
  ReportImpedancePatient,
  ReportProgramPatient,
  ReportTherapyAssessmentPatient,
  ReportTherapyPatient,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ReportBipole
   * @name ReportBipoleGetBipoleReportValues
   * @request GET:/api/v{version}/ReportBipole
   * @secure
   */
  reportBipoleGetBipoleReportValues = (
    version: string,
    query?: {
      /** @format guid */
      StimulatorEid?: string | null;
      /**
       * Start Date Range
       * @format date
       */
      StartDate?: string;
      /**
       * End Date Range
       * @format date
       */
      EndDate?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ReportBipolePatient, any>({
      path: `/api/v${version}/ReportBipole`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ReportImpedance
   * @name ReportImpedanceGetImpedanceReportValues
   * @request GET:/api/v{version}/ReportImpedance
   * @secure
   */
  reportImpedanceGetImpedanceReportValues = (
    version: string,
    query?: {
      /** @format guid */
      StimulatorEid?: string | null;
      /**
       * Start Date Range
       * @format date
       */
      StartDate?: string;
      /**
       * End Date Range
       * @format date
       */
      EndDate?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ReportImpedancePatient, any>({
      path: `/api/v${version}/ReportImpedance`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ReportProgram
   * @name ReportProgramGetProgramReportValues
   * @request GET:/api/v{version}/ReportProgram
   * @secure
   */
  reportProgramGetProgramReportValues = (
    version: string,
    query?: {
      /** @format guid */
      StimulatorEid?: string | null;
      /**
       * Start Date Range
       * @format date
       */
      StartDate?: string;
      /**
       * End Date Range
       * @format date
       */
      EndDate?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ReportProgramPatient, any>({
      path: `/api/v${version}/ReportProgram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ReportTherapyAssmt
   * @name ReportTherapyAssmtGetTherapyAssessmentReport
   * @request GET:/api/v{version}/ReportTherapyAssmt
   * @secure
   */
  reportTherapyAssmtGetTherapyAssessmentReport = (
    version: string,
    query?: {
      /** @format guid */
      StimulatorEid?: string | null;
      /**
       * Start Date Range
       * @format date
       */
      StartDate?: string;
      /**
       * End Date Range
       * @format date
       */
      EndDate?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ReportTherapyAssessmentPatient, any>({
      path: `/api/v${version}/ReportTherapyAssmt`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ReportTherapy
   * @name ReportTherapyGetTherapyReportValues
   * @request GET:/api/v{version}/ReportTherapy
   * @secure
   */
  reportTherapyGetTherapyReportValues = (
    version: string,
    query?: {
      /** @format guid */
      StimulatorEid?: string | null;
      /**
       * Start Date Range
       * @format date
       */
      StartDate?: string;
      /**
       * End Date Range
       * @format date
       */
      EndDate?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ReportTherapyPatient, any>({
      path: `/api/v${version}/ReportTherapy`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Patient
   * @name PatientGetPatientDetail
   * @request GET:/api/v{version}/Patient
   * @secure
   */
  patientGetPatientDetail = (version: string, params: RequestParams = {}) =>
    this.request<Patient, any>({
      path: `/api/v${version}/Patient`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
