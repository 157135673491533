import React, { useState, useRef, useCallback } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Space, Checkbox } from "antd";
import HFXCloudAssessment from "../images/TherapyImages/HFXCloudAssessment.svg";
import InWindow from "../images/TherapyImages/InWindow.svg";
import OutsideWindow from "../images/TherapyImages/OutsideWindow.svg";

import OverallRelief from "../images/TherapyImages/OverallRelief.svg";
import A1 from "../images/ProgramImages/A1.png";
import A2 from "../images/ProgramImages/A2.png";
import A3 from "../images/ProgramImages/A3.png";
import A4 from "../images/ProgramImages/A4.png";
import IQ from "../images/ProgramImages/IQ.png";
import { DataPointThpyAssess, ReportProgramDevice } from "../../api/data-contracts";
import { DateRange, ReportContainer } from "../Report/ReportContainer";
import styles from "./ProgramReport.module.scss";
import Moment from "moment";
import { ReactComponent as NoData } from "../images/no-data-time-period.svg";
import { useIqUpgradeDataPoints } from "../../useIqUpgradePoints";
import { IqUpgradeFilters } from "./IqUpgradeFilters";

const areaAmplitudesKeys = ["A1AmpDPs", "A2AmpDPs", "A3AmpDPs", "A4AmpDPs"] as const;
const areaImages = [A1, A2, A3, A4, IQ];
const areaStrokeColor = ["#73B4D8", "#689F38", "#9773D8", "#F57F17"];
const initialSeriesArray: boolean[] = Array(9).fill(true);
const seriesArrayPlot = ["InWinDPs", "OutWinDPs", "HfxDPs"] as const;
export const iqUpgradeColors = ["#002f63", "#004C9D", "#006be0", "#85bfff"];

const dataPoints = [
  "A1AmpDPs",
  "A2AmpDPs",
  "A3AmpDPs",
  "A4AmpDPs",
  "PgDPs",
  "OverallPReliefDPs",
  "InWinDPs",
  "OutWinDPs",
  "HfxDPs",
  "PgmNames",
] as const;

export interface ProgramReportProps {
  loading: boolean;
  reportDate: Date;
  deviceProgramData: ReportProgramDevice;
  refreshReportData: () => void;
  onDateRangeChanged: (newDateRange: DateRange) => void;
  dateRange: DateRange;
  dateRangeLimits: DateRange;
}

export const ProgramsReport = ({
  deviceProgramData,
  reportDate,
  refreshReportData,
  dateRange,
  dateRangeLimits,
  onDateRangeChanged,
}: ProgramReportProps) => {
  const refreshedAt = new Date(reportDate);

  const dataPointsExists = dataPoints.map(
    property => !!deviceProgramData[property] && deviceProgramData[property].length !== 0
  );

  const noData = dataPointsExists.every(dp => dp === false);
  const [seriesIndex, setSeriesIndex] = useState<number>(null);

  const [seriesCheckedState, setSeriesCheckedState] = useState(initialSeriesArray);

  const { iqUpgradeDataPoints } = useIqUpgradeDataPoints(deviceProgramData.ActiveTherapyPrograms);

  const [iqUpgradesCheckedStates, setIqUpgradesCheckedStates] = useState(
    iqUpgradeDataPoints.map(_ => true)
  );

  // Create and populate the chart
  const chartRef = useRef<am5xy.XYChart>();
  const evalTimeStArray = deviceProgramData.EvalWinStTime.split(":");
  const inWindowStTime = evalTimeStArray[0] + ":" + evalTimeStArray[1];
  const evalTimeEndArray = deviceProgramData.EvalWinEndTime.split(":");
  const inWindowEndTime = evalTimeEndArray[0] + ":" + evalTimeEndArray[1];
  // Component did mount
  React.useLayoutEffect(() => {
    if (!noData) {
      setSeriesCheckedState(new Array(initialSeriesArray.length).fill(true));
      let root = am5.Root.new("chartdivProgram");

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          layout: root.horizontalLayout,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          paddingTop: 40,
        })
      );

      // Create Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, { opposite: true }),
          min: 0,
          max: 100,
          strictMinMax: true,
        })
      );

      yAxis.axisHeader.children.push(
        am5.Label.new(root, {
          text: "Overall Relief, %",
          fill: am5.Color.fromAny("#757982"),
          textAlign: "center",
          x: am5.percent(100),
          fontSize: 10,
        })
      );
      yAxis.axisHeader.get("background").setAll({
        fillOpacity: 0,
      });
      yAxis.axisHeader.set("height", 40);

      yAxis.set("numberFormat", "#");

      //Program Axis
      const y3Renderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 50,
      });

      const y3Axis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: y3Renderer,

          min: 0,

          maxPrecision: 0,

          extraMax: 0.01,

          strictMinMax: true,
        })
      );

      y3Axis.axisHeader.children.push(
        am5.Label.new(root, {
          text: "Programs",
          fill: am5.Color.fromAny("#757982"),
          x: -100,
          fontSize: 10,
        })
      );
      y3Axis.axisHeader.get("background").setAll({
        fillOpacity: 0,
      });
      y3Axis.axisHeader.set("height", 40);
      y3Axis.get("renderer").labels.template.setAll({
        oversizedBehavior: "fit",
        minPosition: 0,
        location: 0,
      });

      //Second Y axis

      const Y2AxisRenderer = am5xy.AxisRendererY.new(root, {});
      const Y2Axis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: Y2AxisRenderer,
          min: 0,
          // max: 10,
        })
      );

      Y2Axis.axisHeader.children.push(
        am5.Label.new(root, {
          text: "Amplitude, mA",
          fill: am5.Color.fromAny("#757982"),
          x: -50,
          fontSize: 10,
        })
      );
      Y2Axis.axisHeader.get("background").setAll({
        fillOpacity: 0,
      });
      Y2Axis.axisHeader.set("height", 40);

      chart.set("paddingTop", 35);
      // chart.set("paddingRight", 35);
      Y2Axis.set("numberFormat", "#.00");

      // Create X-Axis
      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.1,
          baseInterval: {
            timeUnit: "second",
            count: 1,
          },

          renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 60 }),
        })
      );

      // chart/scrollbars/
      const scrollbar = chart.set(
        "scrollbarX",
        am5xy.XYChartScrollbar.new(root, {
          orientation: "horizontal",
          height: 60,
        })
      );
      chart.bottomAxesContainer.children.push(scrollbar);

      const sbDateAxis = scrollbar.chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.1,
          baseInterval: {
            timeUnit: "second",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {}),
        })
      );

      const sbValueAxis = scrollbar.chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      // Date formats for xaxis
      xAxis.get("dateFormats")["day"] = "dd MMM";
      xAxis.get("periodChangeDateFormats")["day"] = "dd MMM YYYY";

      //Amplitude axis series

      areaAmplitudesKeys.forEach((areaKey, areaKeyIndex) => {
        // Only load the area if it has some data
        const data = deviceProgramData[areaKey].every(areaDataPoint => areaDataPoint.Val === 0)
          ? []
          : deviceProgramData[areaKey];

        const series1 = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: areaKey,
            xAxis: xAxis,
            yAxis: Y2Axis,
            valueYField: "Val",
            valueXField: "Ts",
            stroke: am5.color(areaStrokeColor[areaKeyIndex]),
          })
        );

        const sbSeries = scrollbar.chart.series.push(
          am5xy.StepLineSeries.new(root, {
            valueYField: "Val",
            valueXField: "Ts",
            xAxis: sbDateAxis,
            yAxis: sbValueAxis,
          })
        );

        sbSeries.data.setAll(data);
        series1.data.setAll(data);
        series1.fills.template.setAll({
          fillOpacity: 0.1,
          visible: true,
        });

        series1.bullets.push(function (root) {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 4,
              fill: series1.get("stroke"),
            }),
          });
        });
      });

      const onTimeOffTimeData = (value: number) => {
        let timeSecond = 1000;
        let timeMinute = 60 * 1000;
        let milliseconds = " msec";
        let seconds = " sec";
        let minutes = " min";
        if (value < timeSecond) return value + milliseconds;
        else if (value < timeMinute) return value / timeSecond + seconds;
        else return value / timeMinute + minutes;
      };

      /** Augments the seriesData with the the tooltip data */
      const tooltipPreprocess = (seriesData: DataPointThpyAssess[]) => {
        let actLevelArr = ["Undefined", "Increase", "Decrease", "Same", "NA"];
        let medLevelArr = [
          "Undefined",
          "Least",
          "Decrease",
          "NoChange",
          "Increase",
          "Most",
          "NotApplicable",
        ];

        const followUps = deviceProgramData.FollowUpAssessments;
        const activeTherapyPrograms = deviceProgramData.ActiveTherapyPrograms;

        const newSeriesArr = seriesData.map(series => {
          const timeStamp = Moment(series.Ts).format("DD-MMM-YYYY hh:mm A");

          const foundfollowUp = followUps.find(followUp => followUp.Ts === series.Ts);
          const recommended = foundfollowUp?.Recom;
          const useraction = foundfollowUp?.UserAct;
          const oPainRelief = foundfollowUp?.OPainRelief
            ? foundfollowUp?.OPainRelief + "%"
            : undefined;
          const actMedLev =
            foundfollowUp?.ImpFunc &&
            actLevelArr[foundfollowUp.ImpFunc] + "/" + medLevelArr[foundfollowUp.MedLev];

          const foundActive = activeTherapyPrograms.find(active => active.Ts === series.ThpyTs);
          let activeAreaHTML: string[] = [];
          let pgName: string;
          let activeDays: number;
          let activeHours: number;
          if (foundActive) {
            pgName = foundActive.ThpyPgm?.Name;
            const startDate = Moment(Moment(series.ThpyTs)); // is the double moment needed?
            const endDate = Moment(Moment(series.Ts));
            const duration = Moment.duration(endDate.diff(startDate));
            activeDays = duration.days();
            activeHours = duration.hours();

            if (foundActive.IsThpyOn) {
              activeAreaHTML = foundActive.ThpyPgm.ThpyAreas.map((activeArea, activeAreaIndex) => {
                const freqElectr =
                  activeArea.Elctrds[0].Idx +
                  1 +
                  activeArea.Elctrds[0].Pol +
                  "/" +
                  (activeArea.Elctrds[1].Idx + 1) +
                  activeArea.Elctrds[1].Pol;

                const onTimeString = onTimeOffTimeData(activeArea.OnTime);
                const offTimeString = onTimeOffTimeData(foundActive.ThpyPgm.OffTime);

                return `<table class="${styles["area-tooltip-table"]}">
                    <tr>
                      <td><b>A${activeAreaIndex + 1}</b></td>
                    </tr>
                    <tr class="${styles["tooltip-area-content-row"]}">
                      <td>${freqElectr}Freq</td>
                      <td>${activeArea.Freq} Hz</td>
                    </tr>
                    <tr class="${styles["tooltip-area-content-row"]}">
                      <td>PW</td>
                      <td>${activeArea.PW} usec</td>
                    </tr>
                    <tr class="${styles["tooltip-area-content-row"]}">
                      <td>On Time</td>
                      <td>${onTimeString}</td>
                    </tr>
                    <tr class="${styles["tooltip-area-content-row"]}">
                      <td>Off Time</td>
                      <td>${offTimeString}</td>
                    </tr>
                    <tr class="${styles["tooltip-area-content-row"]}">
                      <td>Min.Amp</td>
                      <td>${activeArea.AmpMin.toFixed(2)} mA</td>
                    </tr>
                    <tr class="${styles["tooltip-area-content-row"]}">
                      <td>Max.Amp</td>
                      <td>${activeArea.AmpMax.toFixed(2)} mA</td>
                    </tr>
                    <tr class="${styles["tooltip-area-content-row"]}">
                      <td>Start.Amp</td>
                      <td>${activeArea.AmpSt.toFixed(2)} mA</td>
                    </tr>
                    <tr class="${styles["tooltip-area-content-row"]}">
                      <td>Current.Amp</td>
                      <td>${activeArea.AmpCurrent.toFixed(2)} mA</td>
                    </tr>
                    <tr class="${styles["tooltip-area-content-row"]}">
                      <td>Step Size</td>
                      <td>${activeArea.StepSz.toFixed(2)} mA</td>
                    </tr>
                  </table>`;
              });
            } else {
              activeAreaHTML = ["Therapy Off"];
            }
          }

          const tooltipData = {
            timeStamp,
            recommended,
            useraction,
            oPainRelief,
            actMedLev,
            activeAreaHTML,
            pgName,
            activeDays,
            activeHours,
          };

          return { ...series, object1: tooltipData };
        });
        return newSeriesArr;
      };

      // Electrode series

      interface PgmData {
        category: string;
        Dtls: string;
        Ts: number;
        Val: number;
        showBullets: boolean;
      }

      const programdata: Array<PgmData> = [];
      const names = deviceProgramData.PgmNames;

      y3Axis.get("renderer").labels.template.adapters.add("text", function (text, target) {
        const index = Number(text);
        if (index <= names.length) {
          return names[index];
        }
      });

      const pgDpsData = deviceProgramData["PgDPs"];
      pgDpsData.forEach(pgelement => {
        let obj = names.find(o => o === pgelement.OriPgmName);

        if (obj !== undefined) {
          if (obj === "iQ Mode") {
            programdata.push({
              category: obj,
              Dtls: pgelement["OriPgmName"],
              Ts: pgelement["Ts"],
              Val: pgelement["Val"],
              showBullets: true,
            });
          } else {
            programdata.push({
              category: obj,
              Dtls: pgelement["OriPgmName"],
              Ts: pgelement["Ts"],
              Val: pgelement["Val"],
              showBullets: false,
            });
          }
        }
      });

      programdata.sort((x, y) => x.Ts - y.Ts);

      let series5 = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "Programs",
          xAxis: xAxis,
          yAxis: y3Axis,
          valueYField: "Val",
          valueXField: "Ts",
          stroke: am5.color("#000000"),
        })
      );

      const sbSeries = scrollbar.chart.series.push(
        am5xy.StepLineSeries.new(root, {
          valueYField: "Val",
          valueXField: "Ts",
          xAxis: sbDateAxis,
          yAxis: sbValueAxis,
        })
      );

      sbSeries.data.setAll(programdata);
      series5.data.setAll(programdata);

      series5.bullets.push(function (root, series5, dataItem) {
        const entry = dataItem.dataContext as PgmData;
        if (entry.showBullets === true) {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 3,
              fill: am5.color("#ffffff"),
              stroke: series5.get("stroke"),
            }),
          });
        }
      });

      //OverAll Painrelief series
      let overAllPainReliefData = deviceProgramData["OverallPReliefDPs"];
      let series1 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "OverAll % Relief",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "Val",
          valueXField: "Ts",
          stroke: am5.color("#509F33"),
        })
      );

      series1.strokes.template.setAll({
        strokeWidth: 3,
      });

      const sbSeries1 = scrollbar.chart.series.push(
        am5xy.LineSeries.new(root, {
          valueYField: "Val",
          valueXField: "Ts",
          xAxis: sbDateAxis,
          yAxis: sbValueAxis,
        })
      );

      sbSeries1.data.setAll(overAllPainReliefData);
      series1.data.setAll(overAllPainReliefData);

      seriesArrayPlot.forEach(seriesName => {
        const inWindowData = deviceProgramData[seriesName];
        const newInwindowArr = tooltipPreprocess(inWindowData);
        let name = "";
        let bulletColor: string = "";
        if (seriesName === "InWinDPs") {
          name = "In Window";
          bulletColor = "#509F33";
        } else if (seriesName === "OutWinDPs") {
          name = "Out Window";
          bulletColor = "#F2A900";
        } else if (seriesName === "HfxDPs") {
          name = "HFX Cloud Assessment";
          bulletColor = "#FFDD05";
        }
        let tooltipInWindow = null;

        tooltipInWindow = am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          getLabelFillFromSprite: true,
          labelHTML: `<div class="${styles["tooltip-container"]}">
              <table>
                <tbody>
                  <tr>
                    <td><b>${name}</b></td>
                  </tr> 
                  <tr class="${styles["tooltip-content-row"]}">
                    <td>{object1.timeStamp}</td>
                  </tr> 
                  <tr class="${styles["tooltip-content-row"]}">
                    <td>Program Name</td>
                    <td>{object1.pgName}</td>
                  </tr>
                  <tr class="${styles["tooltip-content-row"]}">
                    <td>Current Pain Relief</td>
                    <td>{object1.oPainRelief}</td>
                  </tr>
                  <tr class="${styles["tooltip-content-row"]}">
                    <td>Active Days</td>
                    <td>{object1.activeDays}</td>
                  </tr>
                  <tr class="${styles["tooltip-content-row"]}">
                    <td>Active Hours</td>
                    <td>{object1.activeHours}</td>
                  </tr>
                  <tr class="${styles["tooltip-content-row"]}">
                    <td>Activity/Med</td>
                    <td>{object1.actMedLev}</td>
                  </tr>
                  <tr class="${styles["tooltip-content-row"]}">
                    <td>Recommended</td>
                    <td>{object1.recommended}</td>
                  </tr>
                  <tr class="${styles["tooltip-content-row"]}">
                    <td>User Action</td>
                    <td>{object1.useraction}</td>
                  </tr>
                </tbody>
              </table>
              {object1.activeAreaHTML}
            </div>
            `,
        });

        tooltipInWindow.get("background").setAll({
          fill: am5.color(0xffffff),
          fillOpacity: 0.8,
          stroke: am5.color(0x000000),
        });

        let series2 = chart.series.push(
          am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "Val",
            valueXField: "Ts",
            tooltipText: "Val",
            showTooltipOn: "hover",
            tooltip: tooltipInWindow,
          })
        );

        const sbSeries2 = scrollbar.chart.series.push(
          am5xy.LineSeries.new(root, {
            valueYField: "Val",
            valueXField: "Ts",
            xAxis: sbDateAxis,
            yAxis: sbValueAxis,
          })
        );

        sbSeries2.data.setAll(newInwindowArr);
        series2.data.setAll(newInwindowArr);

        series2.strokes.template.set("strokeOpacity", 0);
        sbSeries2.strokes.template.set("strokeOpacity", 0);
        if (seriesName === "HfxDPs") {
          const starTemplate = am5.Template.new({});
          series2.bullets.push(function () {
            const graphics = am5.Star.new(root, {
              fill: am5.color(bulletColor),
              spikes: 5,
              innerRadius: am5.percent(30),
            });
            return am5.Bullet.new(root, {
              sprite: graphics,
            });
          });
          series2.set("heatRules", [
            {
              target: starTemplate,
              min: 3,
              max: 50,
              dataField: "value",
              key: "radius",
            },
          ]);
        } else {
          series2.bullets.push(function (root) {
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                radius: 5,
                fill: am5.color(bulletColor),
              }),
            });
          });
        }
      });

      // iq upgrade events
      iqUpgradeDataPoints.forEach((iqDataPoint, index) => {
        var iqRange = xAxis.createAxisRange(
          xAxis.makeDataItem({
            value: iqDataPoint.Ts,
          })
        );

        const label = `iQ ${iqDataPoint.Val.toFixed(1)} Upgrade`;
        const color = am5.Color.fromString(iqUpgradeColors[index]);
        iqRange.get("grid").setAll({
          forceHidden: false,
          strokeOpacity: 0.9,
          stroke: color,
          tooltipText: `[bold]${label}[/]\n{value.formatDate('dd MMM YYYY hh:mm a')}`,
        });

        iqRange.set(
          "bullet",
          am5xy.AxisBullet.new(root, {
            sprite: am5.Label.new(root, {
              fill: color,
              fontSize: 16,
              centerY: 23,
              centerX: 17.25,
              text: "▲",
            }),
          })
        );

        var iqLabel = iqRange.get("label");

        iqLabel.setAll({
          centerY: am5.p100,
          centerX: -12,
          rotation: -90,
          isMeasured: false,
          fontSize: 13,
          text: label,
          fontWeight: "500",
        });
      });

      // Add cursor
      const allseries = chart.series.values;
      chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          snapToSeries: [allseries[6], allseries[7], allseries[8]],
        })
      );
      chartRef.current = chart;
      // setRoot(root);
      return () => {
        root.dispose();
      };
    }
  }, [deviceProgramData, iqUpgradeDataPoints, noData]);

  // This code will only run when props.data changes
  React.useLayoutEffect(() => {
    if (!noData) {
      if (seriesIndex !== null) {
        seriesCheckedState.forEach((chck, chckIndex) => {
          const series = chartRef.current.series.getIndex(chckIndex);
          if (seriesCheckedState[chckIndex]) {
            series.show();
          } else {
            series.hide();
          }
        });
      }

      // show/hide the iq upgrade
      iqUpgradesCheckedStates.forEach((checked, index) => {
        const iqRange = chartRef.current.xAxes.getIndex(0).axisRanges.getIndex(index);
        iqRange.get("label").set("visible", checked);
        iqRange.get("grid").set("visible", checked);
        iqRange.get("bullet").get("sprite").set("visible", checked);
      });
    }
  }, [seriesCheckedState, seriesIndex, noData, iqUpgradesCheckedStates]);

  const handleOnSeriesCheckBoxClicked = (position: number) => {
    setSeriesIndex(position);
    const updatedCheckedState = seriesCheckedState.map((item, index) =>
      index === position ? !item : item
    );

    setSeriesCheckedState(updatedCheckedState);
  };

  const handleClearFilters = useCallback(() => {
    onDateRangeChanged({ startDate: null, endDate: null });
    setSeriesCheckedState(initialSeriesArray);
  }, [onDateRangeChanged]);

  const reportContent = (
    <div className={styles["chart-area"]}>
      {noData ? (
        <div className={styles["no-data-container"]}>
          <NoData />
          <h2>No Data</h2>
          <p>
            There is no data for this patient in the selected time period. Please choose a different
            time period.
          </p>
        </div>
      ) : (
        <div
          id="chartdivProgram"
          data-testid="chartdivProgram"
          style={{ width: "100%", height: "100%" }}
        ></div>
      )}
    </div>
  );

  return (
    <ReportContainer
      onRefreshReportData={refreshReportData}
      lastReportRunDate={refreshedAt}
      onDateRangeChanged={onDateRangeChanged}
      dateRange={dateRange}
      dateRangeLimits={dateRangeLimits}
      onClearFilters={handleClearFilters}
      reportContent={reportContent}
      reportFilters={
        <Space className={styles["legendSeries"]} size={10} direction="vertical">
          <IqUpgradeFilters
            iqUpgradeDataPoints={iqUpgradeDataPoints}
            iqUpgradesCheckedStates={iqUpgradesCheckedStates}
            setIqUpgradesCheckedStates={setIqUpgradesCheckedStates}
          />

          <label className={styles["legendSubFilter"]}>Therapy Program</label>
          {areaAmplitudesKeys.map((areaElement: string, areaIndex: number) => {
            return (
              <div key={`area-${areaIndex + 1}`}>
                <Checkbox
                  type="checkBox"
                  value="Toggle Series #1"
                  name={areaAmplitudesKeys[areaIndex]}
                  onClick={() => {
                    handleOnSeriesCheckBoxClicked(areaIndex);
                  }}
                  checked={seriesCheckedState[areaIndex]}
                />
                <img className={styles["filterIcon"]} src={areaImages[areaIndex]} alt="Heart"></img>
                <div className={styles["filterSeriesText"]}>
                  {areaAmplitudesKeys[areaIndex].substring(0, 2)}
                </div>
              </div>
            );
          })}
          <div className="checkbox">
            <Checkbox
              type="checkBox"
              value="Toggle Series #1"
              name="IQ"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(4);
              }}
              checked={seriesCheckedState[4]}
            />
            <img className={styles["filterIcon"]} src={IQ} alt="Heart"></img>
            <div className={styles["filterSeriesText"]}>Programs</div>
          </div>
          <label className={styles["legendSubFilter"]}>Pain Area</label>
          <div className="checkbox">
            <Checkbox
              type="checkBox"
              value="Toggle Series #1"
              name="Over-All Pain Relief"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(5);
              }}
              checked={seriesCheckedState[5]}
            />
            <img className={styles["filterIcon"]} src={OverallRelief} alt="Heart"></img>
            <div className={styles["filterSeriesText"]}>Overall Relief</div>
          </div>
          <label className={styles["legendSubFilter"]}>Type of Assessments</label>
          <div>
            <Checkbox
              type="checkBox"
              value="Toggle Series #2"
              name="In-Window"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(6);
              }}
              checked={seriesCheckedState[6]}
            />
            <img className={styles["filterIcon"]} src={InWindow} alt="Heart"></img>
            <label className={styles["inwindowLabel"]}>
              <div className={styles["filterSeriesText"]}>In Window</div>
              <label className={styles["inWindowFilter"]}>
                {" "}
                {"  " + inWindowStTime + "-" + inWindowEndTime}{" "}
              </label>
            </label>
          </div>
          <div>
            <Checkbox
              type="checkBox"
              value="Toggle Series #2"
              name="Out-Window"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(7);
              }}
              checked={seriesCheckedState[7]}
            />
            <img className={styles["filterIcon"]} src={OutsideWindow} alt="Heart"></img>
            <div className={styles["filterSeriesText"]}> Out Window</div>
          </div>

          <div>
            <Checkbox
              type="checkBox"
              value="Toggle Series #1"
              name="HFX Cloud Assessment"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(8);
              }}
              checked={seriesCheckedState[8]}
            />
            <img className={styles["filterIcon"]} src={HFXCloudAssessment} alt="Heart"></img>
            <div className={styles["filterSeriesText"]}>HFX Cloud Assessment</div>
          </div>
        </Space>
      }
    />
  );
};

export default ProgramsReport;
