import { Redirect } from "react-router-dom";
import { apiInstance } from "../../api/api-instance";

/** This page takes the tokens from the querystring and puts them into session storage, then redirects to home */
export const Auth: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const refresh_token = queryParams.get("refresh_token");
  if (token !== null && token !== undefined) {
    sessionStorage.setItem("access_token", token);
    apiInstance.setSecurityData(token);
  }
  if (refresh_token != null) {
    sessionStorage.setItem("refresh_token", refresh_token);
  }

  return <Redirect to="/" />;
};
