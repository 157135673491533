import { useState } from "react";
import { LeadDiagram, LeadType } from "./LeadDiagram";

const leadDiagrampProps = {
  leadOneVertebraIndex: 3,
  leadOneTopOffsetPercent: 10,
  leadTwoVertebraIndex: 4,
  leadTwoTopOffsetPercent: 0,
  leadType: LeadType.Double,
};

export const LeadDiagramPage = (props: any) => {
  var [leadState, setLeadState] = useState(leadDiagrampProps);

  return (
    <div>
      <h2>Test page for lead diagram component</h2>
      <div
        style={{
          display: "inline-block",
          verticalAlign: "top",
          paddingRight: "2em",
          textAlign: "right",
        }}
      >
        {Object.keys(leadState).map(keyName => (
          <div>
            {keyName}:{" "}
            {keyName === "leadType" && (
              <select
                style={{ width: "11.4rem" }}
                onChange={e =>
                  setLeadState({ ...leadState, leadType: Number.parseInt(e.currentTarget.value) })
                }
              >
                {Object.keys(LeadType)
                  .filter(lt => !isNaN(lt as any))
                  .map(lt => (
                    <option value={lt}>{LeadType[lt as any]}</option>
                  ))}
              </select>
            )}
            {keyName !== "leadType" && (
              <input
                type="number"
                value={(leadState as any)[keyName]}
                onChange={e =>
                  setLeadState({
                    ...leadState,
                    [keyName]: Number.parseFloat(e.currentTarget.value),
                  })
                }
              />
            )}
          </div>
        ))}
      </div>
      <div style={{ display: "inline-block" }}>
        <LeadDiagram {...leadState} />
      </div>
    </div>
  );
};
