import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { loadBipoleData, selectBipoleReport } from "../../redux/bipoleSlice";
import { ErrorComponent } from "../Error";
import { Loading } from "../Loading";
import { BipoleReport } from "./BipoleReport";

export interface DateRange {
    startDate: Date;
    endDate: Date;
  }
export const BipoleReportContainer: React.FC = () => {
  const selectedDevice = useAppSelector(state => state.patient.selectedDevice);
  const { loading, bipoleData, error } = useSelector(selectBipoleReport);
  const dispatch = useAppDispatch();
  const refreshBipoleData = useCallback(
    async (startDate: Date = null, endDate: Date = null) => {
      dispatch(loadBipoleData({ startDate, endDate }));
    },
    [dispatch]
  );

 

  const reportDate = new Date(bipoleData?.RptDt);
  const deviceBipoleData = bipoleData?.RptBipDevs?.find(
    dev => dev.DevTyp === selectedDevice.deviceType && dev.DevSerial === selectedDevice.serialNumber
  );

  // Reload the data when the selected device changes
  useEffect(() => {
    refreshBipoleData();
  }, [refreshBipoleData, selectedDevice]);


//   const handleRefreshImpedanceData = useCallback(() => {
//     refreshBipoleData(dateRange.startDate, dateRange.endDate);
//   }, [dateRange.endDate, dateRange.startDate, refreshBipoleData]);

const handleRefreshBipoleData = (newDateRange: DateRange) => {
    if (newDateRange.startDate === null && newDateRange.endDate === null) {
      refreshBipoleData();
    } else {
        refreshBipoleData(newDateRange.startDate, newDateRange.endDate);
    }
  };

  if (!selectedDevice) {
    return <div>Please select a device to view the programs report.</div>;
  } else if (error) {
    return <ErrorComponent tokenExpired={error.type === "token expired"} message={error.message} />;
  } else if (!deviceBipoleData) {
    return <Loading />;
  } else {
    return (
      <BipoleReport
        loading={loading}
        reportDate={reportDate}
        deviceBipoleData={deviceBipoleData}
        refreshBipoleData={handleRefreshBipoleData}
      />
    );
  }
};
