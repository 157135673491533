import { Component, PropsWithChildren } from "react";
import { ErrorComponent } from "./Error";

type ErrorBoundaryState =
  | { hasError: false }
  | { hasError: true; error: Error; info: React.ErrorInfo };
export class ErrorBoundary extends Component<PropsWithChildren<{}>, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true, error, info });
    // TODO: log to datadog
  }

  refreshPage(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorComponent
          tokenExpired={false}
          message={this.state.error.toString()}
          description={this.state.info.componentStack}
        />
      );
    } else {
      return this.props.children;
    }
  }
}
