import React from "react";
import ReactTooltip from "react-tooltip";
import { CircularProgressbar } from "react-circular-progressbar";
import Moment from "moment";
import BipoleChartType from "./BipoleChartType";
import Heart from "../../images/Heart.png";
import upArrow from "../../images/up-arrow.png";
import same from "../../images/same.png";
import downArrow from "../../images/down-arrow.png";
import Pills from "../../images/pills.png";
import Walk from "../../images/walk.png";
import Unavailable from "../../images/Unavailable.png";
import PainLevel24 from "../../images/PainLevel24.png";
import PainLevel02 from "../../images/PainLevel02.png";
import PainLevel10 from "../../images/PainLevel10.png";
import PainLevel46 from "../../images/PainLevel46.png";
import PainLevel68 from "../../images/PainLevel68.png";
import PainLevel810 from "../../images/PainLevel810.png";

const BipoleChartBody: React.FC<BipoleChartType> = ({
  value,
  index,
  bipoleArray,
  programIndex,
  ampArray,
}) => {
  var bipoleV: any = {},
    testedDuration: string;

  const painReliefImage = (painLevel: any) => {
    var resourcePath = Unavailable;
    if (painLevel >= 0 && painLevel < 2) {
      resourcePath = PainLevel02;
    } else if (painLevel >= 2 && painLevel < 4) {
      resourcePath = PainLevel24;
    } else if (painLevel >= 4 && painLevel < 6) {
      resourcePath = PainLevel46;
    } else if (painLevel >= 6 && painLevel < 8) {
      resourcePath = PainLevel68;
    } else if (painLevel >= 8 && painLevel < 10) {
      resourcePath = PainLevel810;
    } else if (painLevel === 10) {
      resourcePath = PainLevel10;
    }
    return resourcePath;
  };

  let actLevelArr = ["Undefined", "Increase", "Decrease", "Same", "NA"];
  let medLevelArr = [
    "Undefined",
    "Least",
    "Decrease",
    "NoChange",
    "Increase",
    "Most",
    "NotApplicable",
  ];
  return (
    <tr key={"value-" + value + "-" + index}>
      {ampArray.map((value1: any, index1: any) => {
        if (bipoleArray != null) {
          // get the val
          //var bipoleArr = bipoleVal[value];

          let dataOne: any = bipoleArray.filter((bipole: any) => bipole["Amp"] === value1);
          // let data = dataOne.filter(( bipole :any)  => bipole['BipoleIndex'][0] ===value.split(',')[0] );

          let data: any = [];

          for (let i = 0; i < dataOne.length; i++) {
            var leadOne: any = dataOne[i].BipIdx[0] + 1;
            var leadTwo: any = dataOne[i].BipIdx[1] + 1;
            //  var leadString = leadOne + "," + leadTwo;

            if (leadOne === value["lead0"] && leadTwo === value.lead1) {
              data.push(dataOne[i]);
            }
          }

          //Program Index Filter
          if (programIndex !== 0) {
            data = data.filter((k: any) => k["PgName"] === programIndex);
          }

          bipoleV = {};
          if (Object.keys(data).length > 0) {
            bipoleV = data[0];
            var activityImg = null;
            var medImg = null;
            if (actLevelArr[bipoleV.ImpFunc] === "Increase") {
              activityImg = upArrow;
            } else if (actLevelArr[bipoleV.ImpFunc] === "Decrease") {
              activityImg = downArrow;
            } else if (actLevelArr[bipoleV.ImpFunc] === "Same") {
              activityImg = same;
            }

            if (medLevelArr[bipoleV.MedLev] === "Increase") {
              medImg = upArrow;
            } else if (medLevelArr[bipoleV.MedLev] === "Decrease") {
              medImg = downArrow;
            } else if (medLevelArr[bipoleV.MedLev] === "NoChange") {
              medImg = same;
            }

            var widthV = "100%";
            var className = "blue_data data_flow";
            var time = bipoleV.Dur!;
            var array = time.split(":");
            var minutesFromDays = 0;
            if (array[0].indexOf(".") > -1) {
              var dayHourSplit = array[0].split(".");
              minutesFromDays =
                parseInt(dayHourSplit[0], 10) * 24 * 60 + parseInt(dayHourSplit[1], 10) * 60;
            } else {
              minutesFromDays = parseInt(array[0], 10) * 60;
            }
            var minutes = minutesFromDays + parseInt(array[1], 10);
            if (minutes > 2880) {
              widthV = "100%";
              testedDuration = "Tested > 48 hours";
            } else if (minutes >= 1440 && minutes <= 2880) {
              widthV = "50%";
              testedDuration = "Tested > 24 hours < 48 hours";
            } else if (minutes < 1440) {
              widthV = "25%";
              testedDuration = "Tested < 24 hours";
            }

            if (bipoleV["OPainRelief"]! > 50) {
              if (bipoleV.IsMA === true) {
                className = "data_flow time_Blue_Data_Gradient";
              } else if (bipoleV.IsPD === true) {
                className = "data_flow time_radial_gradient_blue";
              } else {
                className = "blue_data data_flow";
              }
            } else if (bipoleV.OPainRelief! >= 30 && bipoleV.OPainRelief! <= 50) {
              if (bipoleV.IsMA === true) {
                className = "data_flow time_Green_Data_Gradient";
              } else if (bipoleV.IsPD === true) {
                className = "data_flow time_radial_gradient_green";
              } else {
                className = "green_data data_flow";
              }
            } else if (bipoleV.OPainRelief! < 30) {
              if (bipoleV.IsMA === true) {
                className = "data_flow time_Yellow_Data_Gradient";
              } else if (bipoleV.IsPD === true) {
                className = "data_flow time_radial_gradient_yellow";
              } else {
                className = "yellow_data data_flow";
              }
            }

            //Is Favorite
            let imageIsFavorite;
            if (bipoleV.IsFav) {
              imageIsFavorite = (
                <img
                  src={bipoleV.IsFav === true ? Heart : ""}
                  alt="Heart"
                  width="19"
                  height="19"
                ></img>
              );
            }

            /** Uniquely identify bipole cell. This needs to be unique in order for the correct tooltip to show */
            const bipoleId =  `${new Date(bipoleV.AssmtDt).getTime()}-${bipoleV.BipIdx[0]}`;

            return (
              <td data-testid="active-bipole-cell" key={"value1-" + value1 + "-" + index1}>
                <div data-tip={bipoleV} data-for={bipoleId} className="data">
                  <div className="heartImage">{imageIsFavorite} </div>
                  <div className={className} style={{ width: widthV }} />
                </div>
                <React.Fragment>
                  <ReactTooltip
                    id={bipoleId}
                    type="info"
                    effect="float"
                    place="right"
                    border={true}
                    textColor="#000"
                    backgroundColor="#FFF"
                    borderColor="#000"
                    arrowColor="#fff"
                  >
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>Assessment Results</div>
                    <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {Moment(bipoleV.AssmtDt).format("DD-MMM-YYYY hh:mm A")}
                    </div>
                    <div className="therapyDuration" style={{ fontSize: "12px" }}>
                      <div>Therapy Duration</div>
                      <div style={{ width: "80px" }}>{testedDuration}</div>{" "}
                    </div>
                    <div style={{ display: "flex" }}>
                      {bipoleV.IsFav ? (
                        <div style={{ paddingRight: "5px", display: "flex" }}>
                          <img
                            src={bipoleV.IsFav === true ? Heart : ""}
                            alt="Heart"
                            width="20"
                            height="20"
                          ></img>{" "}
                          <div style={{ fontWeight: "bold", paddingLeft: "3px" }}>
                            {bipoleV.PgName}
                          </div>
                        </div>
                      ) : null}
                      <div style={{ paddingLeft: "5px" }}>
                        E{bipoleV.BipIdx[0] + 1}/E
                        {bipoleV.BipIdx[1] + 1}
                      </div>
                    </div>

                    <table className="assessment">
                      <tbody>
                        <tr style={{ borderBottom: " 1px solid", borderColor: "lightgray" }}>
                          <td style={{ fontSize: "14px" }}>OverAll Relief</td>
                          <td>
                            <CircularProgressbar
                              value={bipoleV.OPainRelief}
                              text={`${bipoleV.OPainRelief}%`}
                              circleRatio={0.5}
                              styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Customize transition animation
                                  transition: "stroke-dashoffset 0.5s ease 0s",
                                  // Rotate the path
                                  transform: "rotate(0.75turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color
                                  stroke: "#d6d6d6",
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Rotate the trail
                                  transform: "rotate(0.75turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: "#000000",
                                  // Text size
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: "#000000",
                                },
                              }}
                            />
                          </td>
                        </tr>
                        {bipoleV.PScores.map((scoreElement: any, scoreIndex: any) => {
                          return (
                            <tr
                              key={`assessment-${scoreIndex}`}
                              style={{ borderBottom: "1px solid", borderColor: "lightgray" }}
                            >
                              <td>{scoreElement.PReg}</td>
                              <td>
                                <img
                                  src={painReliefImage(scoreElement.Score)}
                                  alt="Ok"
                                  width="20"
                                  height="20"
                                ></img>{" "}
                                {"  "}
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    display: "inline",
                                  }}
                                >
                                  {scoreElement.Score}/10{" "}
                                </div>
                              </td>
                            </tr>
                          );
                        })}

                        <tr style={{ borderBottom: " 1px solid", borderColor: "lightgray" }}>
                          <td>Activity level</td>
                          <td>
                            <img src={Walk} alt="Ok" width="20" height="20"></img>
                            {activityImg ? (
                              <img src={activityImg} alt="Ok" width="20" height="20"></img>
                            ) : (
                              actLevelArr[bipoleV.ImpFunc]
                            )}
                          </td>
                        </tr>
                        <tr style={{ borderBottom: " 1px solid", borderColor: "lightgray" }}>
                          <td>Pain Medication Intake</td>
                          <td>
                            <img src={Pills} alt="Ok" width="20" height="20"></img>
                            {medImg ? (
                              <img src={medImg} alt="Ok" width="20" height="20"></img>
                            ) : (
                              medLevelArr[bipoleV.MedLev]
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ReactTooltip>
                </React.Fragment>
              </td>
            );
          } else {
            return (
              <td key={"value1-" + value1 + "-" + index1}>
                <div className="data">
                  <div className="white_data data_flow" style={{ width: "100%" }} />
                </div>
              </td>
            );
          }
        } else {
          return (
            <td key={"value1-" + value1 + "-" + index1}>
              <div className="data">
                <div className="white_data data_flow" />
              </div>
            </td>
          );
        }
      })}
    </tr>
  );
};

export default BipoleChartBody;
