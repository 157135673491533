import { Redirect, Route } from "react-router";
import { Layout } from "./components/Layout/Layout";
import { TherapyReportContainer } from "./components/TherapyReport/TherapyReportContainer";
import { ProgramReportContainer } from "./components/ProgramsReport/ProgramReportContainer";
import { BipoleReportContainer } from "./components/BipoleReport/BipoleReportContainer";
import Patient from "./components/Patient";

import "./common.css";
import "react-circular-progressbar/dist/styles.css";
import { Auth } from "./components/Auth/Auth";
import { LeadDiagramPage } from "./components/LeadDiagram/LeadDiagramPage";
import { ImpedanceReportContainer } from "./components/ImpedanceReport/ImpedanceReportContainer";
import { RequirePatient } from "./RequirePatient";
import "antd/dist/antd.min.css";
import { RequireConfig } from "./RequireConfig";
import { ProgramDetailsReportContainer } from "./components/ProgramDetailsReport/ProgramDetailsReportContainer";
import { ErrorBoundary } from "./components/ErrorBoundary";

function App() {
  return (
    <Layout>
      <ErrorBoundary>
        <RequireConfig>
          <Route path="/auth" component={Auth} />
          <RequirePatient>
            <Route exact path="/" component={() => <Redirect to="/report-therapy" />} />
            <Route path="/patient" component={Patient} />
            <Route path="/report-therapy" component={TherapyReportContainer} />
            <Route path="/report-programs" component={ProgramReportContainer} />
            <Route path="/report-bipole" component={BipoleReportContainer} />
            <Route path="/report-impedance" component={ImpedanceReportContainer} />
            <Route path="/report-program-details" component={ProgramDetailsReportContainer} />
            <Route path="/lead-diagram" component={LeadDiagramPage} />
          </RequirePatient>
        </RequireConfig>
      </ErrorBoundary>
    </Layout>
  );
}

export default App;
