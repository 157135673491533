import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useAppSelector } from "./redux/hooks";

export const Rum = () => {
    const { applicationId, clientToken, env, version, sessionReplaySampleRate } = useAppSelector(state => state.config.datadogRum);
  useEffect(() => {
    datadogRum.init({
      applicationId,
      clientToken,
      site: "datadoghq.com",
      service: "web_report",
      env,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();
  }, [applicationId, clientToken, env, sessionReplaySampleRate, version]);
  return <></>;
};
