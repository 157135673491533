import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { loadProgramData, selectProgramReport } from "../../redux/programSlice";
import { ErrorComponent } from "../Error";
import { Loading } from "../Loading";
import { DateRange } from "../Report/ReportContainer";
import { ProgramsReport } from "./ProgramsReport";

export const ProgramReportContainer: React.FC = () => {
  const selectedDevice = useAppSelector(state => state.patient.selectedDevice);
  const { loading, programData, error } = useSelector(selectProgramReport);
  const dispatch = useAppDispatch();
  const refreshProgramData = useCallback(
    async (startDate: Date = null, endDate: Date = null) => {
      dispatch(loadProgramData({ startDate, endDate }));
    },
    [dispatch]
  );

  const reportDate = new Date(programData?.RptDt);
  const deviceProgramData = programData?.RptPrgDevs?.find(
    dev => dev.DevTyp === selectedDevice.deviceType && dev.DevSerial === selectedDevice.serialNumber
  );
  const dateRange = {
    startDate: new Date(deviceProgramData?.DtRngStTs),
    endDate: new Date(deviceProgramData?.DtRngEndTs),
  };
  const dateRangeLimits = {
    startDate: new Date(deviceProgramData?.DtRngMinTs),
    endDate: new Date(deviceProgramData?.DtRngMaxTs),
  };

  // Reload the data when the selected device changes
  useEffect(() => {
    refreshProgramData();
  }, [refreshProgramData, selectedDevice]);

  const handleDateRangeChanged = (newDateRange: DateRange) => {
    if (newDateRange.startDate === null && newDateRange.endDate === null) {
      refreshProgramData();
    } else {
      refreshProgramData(newDateRange.startDate, newDateRange.endDate);
    }
  };
  const handleRefreshImpedanceData = useCallback(() => {
    refreshProgramData(dateRange.startDate, dateRange.endDate);
  }, [dateRange.endDate, dateRange.startDate, refreshProgramData]);

  if (!selectedDevice) {
    return <div>Please select a device to view the programs report.</div>;
  } else if (error) {
    return <ErrorComponent tokenExpired={error.type === "token expired"} message={error.message} />;
  } else if (!deviceProgramData) {
    return <Loading />;
  } else {
    return (
      <ProgramsReport
        loading={loading}
        reportDate={reportDate}
        deviceProgramData={deviceProgramData}
        refreshReportData={handleRefreshImpedanceData}
        dateRange={dateRange}
        dateRangeLimits={dateRangeLimits}
        onDateRangeChanged={handleDateRangeChanged}
      />
    );
  }
};
