import React from 'react'
import BipoleAmplitudeType from './BipoleAmplitudeType';

export const BipoleAmplitudeTable: React.FC<BipoleAmplitudeType> = ({ampArray}) => {
  return (
    <table
    className="table"
    cellSpacing={5}
    cellPadding={10}
    style={{ marginBottom: 0, border: "2px solid transparent" }}
  >
    <thead>
      <tr>
        {ampArray.map((amp: any, index: any) => {
          return (
            <th
              className="text-center"
              key={"amp-" + amp + "-" + index}
            >
              <div className="head_data">{amp.toFixed(2)}</div>
            </th>
          );
        })}
      </tr>
    </thead>
  </table>
  )
}