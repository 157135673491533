import React, { useCallback } from "react";
import {
  ActivityChangeType,
  AssessmentAppSourceType,
  EffectiveType,
  ReportTherapyAssessmentDevice,
} from "../../api/data-contracts";
import styles from "./ProgramDetailsReport.module.scss";
import { DateRange, ReportContainer } from "../Report/ReportContainer";
import { ReactComponent as NoData } from "../images/no-data-time-period.svg";
import moment from "moment";
import { Divider } from "antd";

const getPainReliefClass = (painRelief: number) => {
  if (painRelief < 30) {
    return styles.low;
  } else if (painRelief < 60) {
    return styles.medium;
  } else {
    return styles.high;
  }
};

const getActivityChange = (activityChange: ActivityChangeType) => {
  return ActivityChangeType[activityChange];
};

const getMedicationChange = (medicationChange: EffectiveType) => {
  switch (medicationChange) {
    case EffectiveType.NoChange:
      return "No Change";
    case EffectiveType.Decrease:
      return "↓ Decrease";
    case EffectiveType.Increase:
      return "↑ Increase";
    case EffectiveType.NotApplicable:
      return "N/A";
    default:
      return EffectiveType[medicationChange];
  }
};

// Sleep change is hidden until the data can be improved
// const getSleepChange = (sleepChange: SleepImprovementType) => {
//   switch (sleepChange) {
//     case SleepImprovementType.Undefined:
//       return "Uknown";
//     default:
//       return SleepImprovementType[sleepChange];
//   }
// };

const getAssessmentSource = (assessmentSource: AssessmentAppSourceType) => {
  switch (assessmentSource) {
    case AssessmentAppSourceType.NevroCloud:
      return "HFX Cloud";
    case AssessmentAppSourceType.PatientApplication:
      return "Patient App";
    case AssessmentAppSourceType.Undefined:
      return "Unknown";
    default:
      return AssessmentAppSourceType[assessmentSource];
  }
};
export interface ProgramDetailsReportProps {
  loading: boolean;
  reportDate: Date;
  deviceProgramDetails: ReportTherapyAssessmentDevice;
  refreshReportData: () => void;
  onDateRangeChanged: (newDateRange: DateRange) => void;
  dateRange: DateRange;
  dateRangeLimits: DateRange;
}

export const ProgramDetailsReport: React.FC<ProgramDetailsReportProps> = ({
  deviceProgramDetails,
  reportDate,
  refreshReportData,
  dateRange,
  dateRangeLimits,
  onDateRangeChanged,
}) => {
  const refreshedAt = new Date(reportDate);

  const handleClearFilters = useCallback(() => {
    // Clear the date ranges
    onDateRangeChanged({ startDate: null, endDate: null });
  }, [onDateRangeChanged]);

  const noData = deviceProgramDetails.ActThpyPgms.length === 0;

  const reportContent = (
    <div className={styles.container}>
      {noData ? (
        <div className={styles["no-data-container"]}>
          <NoData />
          <h2>No Data</h2>
          <p>
            There is no data for this patient in the selected time period. Please choose a different
            time period.
          </p>
        </div>
      ) : (
        <div>
          <h2>Total Therapy: {deviceProgramDetails.ActThpyPgms.length}</h2>
          <h3>
            Total Assessments:{" "}
            {deviceProgramDetails.ActThpyPgms.reduce((sum, at) => (sum += at.FUAssmt?.length ?? 0), 0)}
          </h3>
          {deviceProgramDetails.ActThpyPgms.map(at => (
            <div className={styles["program-container"]} key={at.ThpyDt}>
              <div className={styles["program-grid"]}>
                {at.IsThpyOn ? (
                  <span className={`${styles.label} ${styles.on}`}>Therapy On</span>
                ) : (
                  <span className={`${styles.label} ${styles.off}`}>Therapy Off</span>
                )}
                <span className={styles.value}>
                  {moment(new Date(at.Ts)).format("DD MMM YYYY hh:mm:ss A")}
                </span>
                <Divider className={styles.divider} />
                <span className={styles.label}>
                  {at.IsThpyOn ? "Active" : "Duration"} Days-Hours
                </span>
                <span className={styles.value}>
                  {moment.duration(at.Dur).days()}d-{moment.duration(at.Dur).hours()}h
                </span>
                <Divider className={styles.divider} />
                {at.IsThpyOn && (
                  <>
                    <span className={styles.label}>Total On Time</span>
                    <span className={styles.value}>{at.ThpyPgm.TotalOnTime}%</span>
                    <Divider className={styles.divider} />
                    {at.ThpyPgm.ThpyAreas.map(area => (
                      <div key={area.Idx.toString()}>
                        <span className={styles.label}>A{area.Idx + 1}</span>
                        <span className={styles.value}>
                          Bipole ={" "}
                          {area.Elctrds.map(
                            electrode => `${electrode.Idx + 1}${electrode.Pol}`
                          ).join("/")}{" "}
                          OnTime = {area.OnTime / 1000} sec Freq = {area.Freq.toLocaleString()}Hz
                          Amp = {area.AmpCurrent}mA
                        </span>
                        <Divider className={styles.divider} />
                      </div>
                    ))}
                  </>
                )}
              </div>
              {at.FUAssmt == null || at.FUAssmt.length === 0 ? (
                <div>
                  No assessments were taken during this programming in the specified time range.
                </div>
              ) : (
                <table className={styles["assessment-table"]}>
                  <thead>
                    <tr>
                      <th>Assessment Date</th>
                      <th>Window</th>
                      <th>Assessment Source</th>
                      <th>Overall Pain Relief</th>
                      <th>Activity</th>
                      <th>Medication</th>
                      {/* <th>Sleep</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {at.FUAssmt.map(assessment => (
                      <tr key={assessment.Ts}>
                        <td>{moment(new Date(assessment.Dt)).format("DD MMM YYYY hh:mm:ss A")}</td>
                        <td>
                          {getAssessmentSource(assessment.AssmtAppSrcType) === "HFX Cloud" ? (
                            <div className={styles["assessmentType"]}>
                              <div className={styles["star"]}></div>
                              <div>Not Available</div>
                            </div>
                          ) : (
                            <div className={styles["assessmentType"]}>
                              <div
                                className={`${styles["assessment-circle"]} ${
                                  assessment.IsInWindows ? styles.inside : styles.outside
                                }`}
                              ></div>
                              <div>{assessment.IsInWindows ? " In Window" : " Outside Window"}</div>
                            </div>
                          )}
                        </td>
                        <td>{getAssessmentSource(assessment.AssmtAppSrcType)}</td>
                        <td>
                          <div
                            className={`${styles["assessment-circle"]} ${
                              styles["pain-relief"]
                            } ${getPainReliefClass(assessment.OPainRelief)}`}
                          ></div>{" "}
                          {assessment.OPainRelief}%
                        </td>
                        <td>{getActivityChange(assessment.ImpFunc)}</td>
                        <td>{getMedicationChange(assessment.MedLev)}</td>
                        {/* <td>{getSleepChange(assessment.SleepImp)}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <ReportContainer
      onRefreshReportData={refreshReportData}
      lastReportRunDate={refreshedAt}
      onDateRangeChanged={onDateRangeChanged}
      dateRange={dateRange}
      dateRangeLimits={dateRangeLimits}
      onClearFilters={handleClearFilters}
      reportContent={reportContent}
      reportFilters={<></>}
    />
  );
};
