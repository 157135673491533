import { ReactComponent as ForbiddenImage } from "./images/forbidden.svg";
import styles from "./Forbidden.module.scss";

export const Forbidden = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ForbiddenImage />
        <h2>It appears that you are not authorized to view this data.</h2>
        <p>Please contact Nevro IT for access.</p>
      </div>
    </div>
  );
};
