import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { apiInstance } from "../api/api-instance";
import { max } from "../array-utils";

// Define a type for the slice state

export interface Device {
  deviceType: string;
  serialNumber: string;
  stimEid: string;
  /** Number representation of the date. to get the date use new Date(assignedDate) */
  assignedDate: number;
}

export interface ApiError {
  type: "error" | "token expired" | "forbidden";
  message: string;
}
interface PatientState {
  patientId?: string;
  selectedDevice?: Device;
  devices: Device[];
  error?: ApiError;
}

// Define the initial state using that type
const initialState: PatientState = {
  devices: [],
};

export const getPatient = createAsyncThunk("patient/getPatient", async (_, { rejectWithValue }) => {
  try {
    const patient = await apiInstance.patientGetPatientDetail("1");
    return patient;
  } catch (error: any) {
    if (error.status === 401) {
      return rejectWithValue({ type: "token expired", message: "Token Expired" });
    } 
    else if(error.status === 403){
      return rejectWithValue({ type: "forbidden", message: "Forbidden" });
    }else {
      // Get error message from response if available
      const message = error.error?.Message || error.message;
      return rejectWithValue({ type: "error", message });
    }
  }
});

export const patientSlice = createSlice({
  name: "redux",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedDevice: (state, action: PayloadAction<Device>) => {
      state.selectedDevice = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPatient.pending, (state, action) => {
      return initialState;
    });
    builder.addCase(getPatient.fulfilled, (state, action) => {
      state.error = undefined;
      state.patientId = action.payload.NevroPId;
      state.devices = action.payload.Stims.map(stim => ({
        deviceType: stim.DevTyp,
        serialNumber: stim.DevSerial,
        stimEid: stim.StimEid,
        assignedDate: Date.parse(stim.DevAsgDt),
      }));
      // select the newest device
      state.selectedDevice = max(state.devices, a => a.assignedDate);
    });
    builder.addCase(getPatient.rejected, (state, action) => {
      const error = action.payload as ApiError;
      return {
        ...initialState,
        error,
      };
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const selectPatientId = (state: RootState) => state.patient.patientId;
export const selectPatientError = (state: RootState) => state.patient.error;
export default patientSlice.reducer;
