import { Button, DatePicker, Divider } from "antd";
import styles from "./ReportContainer.module.scss";
import { ReactComponent as FilterIcon } from "../images/FilterImages/Filters.svg";
import { ReactComponent as RefreshIcon } from "../images/refresh.svg";
import { ReactComponent as ClearFiltersIcon } from "../images/clear-all.svg";
import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";

export interface DateRange {
  startDate: Date;
  endDate: Date;
}
interface ReportContainerProps {
  onRefreshReportData: () => void;
  lastReportRunDate: Date;
  onDateRangeChanged: (range: DateRange) => void;
  dateRange: DateRange;
  dateRangeLimits: DateRange;
  onClearFilters: () => void;
  /** The report specific filters to render in the filters box */
  reportFilters?: React.ReactNode;
  reportContent: React.ReactNode;
}

export const ReportContainer = ({
  onRefreshReportData,
  lastReportRunDate,
  onDateRangeChanged,
  dateRange,
  dateRangeLimits,
  onClearFilters,
  reportFilters,
  reportContent,
}: ReportContainerProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleToggleFilters = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen]);

  const datePickerValue = useMemo(
    () =>
      [moment(dateRange.startDate), moment(dateRange.endDate)] as [moment.Moment, moment.Moment],
    [dateRange.endDate, dateRange.startDate]
  );

  const handleDateRangeChanged = useCallback(
    ([startDateMoment, endDateMoment]: [moment.Moment, moment.Moment]) => {
      const startDate = startDateMoment.toDate();
      const endDate = endDateMoment.toDate();
      onDateRangeChanged({ startDate, endDate });
    },
    [onDateRangeChanged]
  );

  const disabledDate = (date: moment.Moment) => {
    const startOfDay = date.startOf("day").toDate();
    return startOfDay < dateRangeLimits.startDate || startOfDay > dateRangeLimits.endDate;
  };

  const handleRefreshClicked = useCallback(() => onRefreshReportData(), [onRefreshReportData]);

  return (
    <div className={styles["content"]}>
      <div className={styles["chart-column"]}>
        <div className={styles["chart-column-button"]}>
          <Button
            className={styles["refresh-button"]}
            icon={<RefreshIcon className={styles["button-icon"]} />}
            onClick={handleRefreshClicked}
            data-testid={"refresh-button"}
          >
            Last refreshed at {moment(lastReportRunDate).format("hh:mm:ss A")}
          </Button>
        </div>
        <div className={styles["chart-column-chart"]}>{reportContent}</div>
      </div>
      <div className={`${styles["filter-drawer"]} ${drawerOpen ? styles.open : ""}`}>
        <Button
          type="link"
          className={styles["clear-filters-button"]}
          data-testid="clear-filters-button"
          onClick={onClearFilters}
        >
          <ClearFiltersIcon height="15" width="15" className={styles["clear-filters-icon"]} />
          Clear all
        </Button>
        <Button
          className={styles["filter-button"]}
          onClick={handleToggleFilters}
          icon={<FilterIcon className={styles["button-icon"]} />}
          type={drawerOpen ? "text" : undefined}
        >
          Filters
        </Button>

        <div className={styles["filter-drawer-content"]}>
          <Divider />
          <div>
            Analysis Dates
            <DatePicker.RangePicker
              value={datePickerValue}
              picker="date"
              allowClear={false}
              onChange={handleDateRangeChanged}
              disabledDate={disabledDate}
              format={"YYYY-MM-DD"}
              data-testid="date-range-picker"
            />
          </div>
          <Divider />
          {reportFilters}
        </div>
      </div>
    </div>
  );
};
