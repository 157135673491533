import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./NavMenu.module.scss";
import NevroBrandImg from "./NevroBrandImg.png";
import reportIcon from "../../images/reports.png";

import { Menu, Divider, Select } from "antd";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { patientSlice } from "../../../redux/patientSlice";
import MenuDivider from "antd/lib/menu/MenuDivider";

const { Option } = Select;

const NavMenu = () => {
  const dispatch = useAppDispatch();
  const { patientId, selectedDevice, devices } = useAppSelector(state => state.patient);
  const { pathname } = useLocation();
  const reports = [
    { label: "Therapy", path: "/report-therapy" },
    { label: "Program", path: "/report-programs" },
    {
      label: (
        <>
          Program <br />
          Details
        </>
      ),
      path: "/report-program-details",
    },
    { label: "Bipole", path: "/report-bipole" },
    { label: "Impedance", path: "/report-impedance" },
  ];

  const handleSelectedDeviceChange = (newStimEid: string) => {
    const newSelectedDevice = devices.find(d => d.stimEid === newStimEid);
    if (newSelectedDevice) {
      dispatch(patientSlice.actions.setSelectedDevice(newSelectedDevice));
    } else {
      dispatch(patientSlice.actions.setSelectedDevice(null));
    }
  };

  return (
    <div className={styles["nav-menu-container"]}>
      <div className={styles["nav-menu-logo"]}>
        <img style={{ width: 120 }} src={NevroBrandImg} alt="Brand"></img>
      </div>
      <Divider plain className={styles.divider}></Divider>
      <Menu
        theme="dark"
        defaultOpenKeys={["Reports"]}
        selectedKeys={[pathname]}
        className={styles["nav-menu"]}
        mode="inline"
      >
        <Menu.Item key="/patient" className={styles["patient-menu-item"]}>
          <Link to="/patient" className={styles["patient-link"]}>
            <div>Patient</div>
            <div className={styles["patient-id"]}>{patientId}</div>
          </Link>
        </Menu.Item>
        <Menu.Item key="DeviceId" disabled className={styles["device-menu-item"]}>
          <div>Device</div>
          <Select
            value={selectedDevice?.stimEid}
            className={styles["device-select"]}
            onChange={handleSelectedDeviceChange}
            bordered={false}
          >
            {devices.map(device => (
              <Option
                key={device.stimEid}
                value={device.stimEid}
                selected={device.stimEid === selectedDevice?.stimEid}
              >
                {device.deviceType + " " + device.serialNumber}
              </Option>
            ))}
          </Select>
        </Menu.Item>
        <MenuDivider></MenuDivider>
        <Menu.SubMenu key="Reports" title="Reports" icon={<img src={reportIcon} alt="Reports" />}>
          {reports.map(report => (
            <Menu.Item key={report.path}>
              <Link to={report.path}>{report.label}</Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
        <MenuDivider></MenuDivider>
      </Menu>
    </div>
  );
};

export default NavMenu;
