import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Forbidden } from "./components/Forbidden";
import { Loading } from "./components/Loading";
import { Relaunch } from "./components/Relaunch";
import { useAppDispatch } from "./redux/hooks";
import { getPatient, selectPatientError, selectPatientId } from "./redux/patientSlice";

/** Container that loads the patient data in redux if it is not already there.
 * If the patient data is missing, renders a spinner until the data has been loaded*/
export const RequirePatient: React.FC<any> = ({ children }) => {
  const patientId = useSelector(selectPatientId);
  const error = useSelector(selectPatientError);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!patientId && !error) {
      dispatch(getPatient());
    }
  }, [dispatch, error, patientId]);

  if (error?.type === "token expired") {
    return <Relaunch />;
  } else if (error?.type === "forbidden") {
    return <Forbidden />;
  } else if (error) {
    return (
      <div>
        <h3>An error occurred getting the patient information.</h3>
        <p>Please close this page and navigate to the portal from Salesforce.</p>
        <small>Details: {error.message}</small>
      </div>
    );
  } else {
    return patientId != null ? children : <Loading />;
  }
};
