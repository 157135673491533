import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { apiInstance } from "../api/api-instance";
import { ReportBipolePatient } from "../api/data-contracts";
import moment from "moment";
import { ApiError } from "./patientSlice";

interface BipoleState {
  bipoleData?: ReportBipolePatient;
  loading: boolean;
  error?: ApiError;
}

// Define the initial state using that type
const initialState: BipoleState = {
  loading: false,
};

export const loadBipoleData = createAsyncThunk<
  ReportBipolePatient,
  { startDate: Date; endDate: Date },
  { state: RootState }
>(
  "bipole/loadBipoleData",
  async ({ startDate = null, endDate = null }, { getState, rejectWithValue }) => {
    const {
      patient: { selectedDevice },
    } = getState();
    if (selectedDevice?.stimEid) {
      if (null != startDate && null != endDate) {
        // startDate: always set to start of day
        startDate = moment(startDate).startOf("day").toDate();
        // endDate:
        // If it is Today, set current date
        // If it is Not Today, set end of day.
        var isCurrentDate = moment(endDate).isSame(new Date(), "day");
        if (isCurrentDate) {
          endDate = new Date();
        } else {
          endDate = moment(endDate).endOf("day").toDate();
        }
      }

      try {
        return await apiInstance.reportBipoleGetBipoleReportValues("1", {
          StimulatorEid: selectedDevice.stimEid,
          // send in UTC DateTime
          StartDate: startDate?.toISOString(),
          EndDate: endDate?.toISOString(),
        });
      } catch (error: any) {
        console.log(JSON.stringify(error));
        if (error.status === 401) {
          return rejectWithValue({ type: "token expired", message: "Token Expired" });
        } else {
          // Get error message from response if available
          const message = error.error?.Message || error.message;
          return rejectWithValue({ type: "error", message });
        }
      }
    }
  }
);

export const bipoleSlice = createSlice({
  name: "bipole",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadBipoleData.pending, state => {
      state.loading = true;
    });
    builder.addCase(loadBipoleData.fulfilled, (state, action) => {
      state.loading = false;
      state.bipoleData = action.payload;
    });
    builder.addCase(loadBipoleData.rejected, (state, action) => {
      const error = action.payload as ApiError;
      return {
        ...initialState,
        error,
      };
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const selectBipoleReport = (state: RootState) => state.bipoleReport;
