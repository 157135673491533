import React from "react";
import { vertebralColumnConstants } from "./VertebralColumnConstants";
import styles from "./LeadDiagram.module.scss";
import { Lead } from "./Lead";

export enum LeadType {
  Double = 0,
  DoubleSwapped = 1,
  Paddle = 2,
  Single = 3,
  Other = 4,
  Surgical5x2 = 5,
}



interface LeadDiagramProps {
  vertebralHeights?: number[];
  leadOneVertebraIndex: number;
  leadOneTopOffsetPercent: number;
  leadTwoVertebraIndex: number;
  leadTwoTopOffsetPercent: number;
  leadType: LeadType;
}

export const LeadDiagram: React.FC<LeadDiagramProps> = (props: LeadDiagramProps) => {
  const vertebralData = vertebralColumnConstants.vertabrae.map((vertebra, index) => ({
    ...vertebra,
    height: props.vertebralHeights?.[index] ?? vertebra.height
  }))
  return (
    <div>
      {vertebralData.map((vertabra, index) => (
        <div className={styles["vertebral-row"]}>
          <div className={styles["vertebral-name"]}>{vertabra.name}</div>
          <img
            alt={vertabra.name}
            height={vertabra.height * 4}
            src={`/images/LeadDiagram/vertebrae/${vertabra.name}.svg`}
          />
          {props.leadOneVertebraIndex === index && (<Lead leadNumber={1} leadType={props.leadType} topOffsetPercent={props.leadOneTopOffsetPercent} />)}
          {props.leadTwoVertebraIndex === index && (<Lead leadNumber={2} leadType={props.leadType} topOffsetPercent={props.leadTwoTopOffsetPercent} />)}
        </div>
      ))}
    </div>
  );
};
