import { Checkbox } from "antd";
import { DataPoint } from "../../api/data-contracts";
import styles from "./ProgramReport.module.scss";
import { iqUpgradeColors } from "./ProgramsReport";

export const IqUpgradeFilters = ({
  iqUpgradeDataPoints,
  iqUpgradesCheckedStates,
  setIqUpgradesCheckedStates,
}: {
  iqUpgradeDataPoints: DataPoint[];
  iqUpgradesCheckedStates: boolean[];
  setIqUpgradesCheckedStates: (newState: boolean[]) => void;
}) => {
  return (
    <div>
      <label className={styles["legendSubFilter"]}>HFX iQ Upgrade</label>
      {iqUpgradeDataPoints.length === 0 && (
        <div>
          <div className={styles.filterSeriesText}>
            No upgrades within the report period.
          </div>
        </div>
      )}
      {iqUpgradeDataPoints.map((iqUpgrade, index) => (
        <div key={iqUpgrade.Val}>
          <Checkbox
            type="checkBox"
            value="Toggle iQ upgrade"
            name={"iqUpgradeDataPoints"}
            onClick={() => {
              const newState = [...iqUpgradesCheckedStates];
              newState[index] = !newState[index];
              setIqUpgradesCheckedStates(newState);
            }}
            checked={iqUpgradesCheckedStates[index]}
          />

          <span className={styles["iqFilterIcon"]} style={{ color: iqUpgradeColors[index] }}>
            ▲
          </span>
          <label className={styles["inwindowLabel"]}>
            <div className={styles["filterSeriesText"]}>Version {iqUpgrade.Val.toFixed(1)}</div>
            <label className={styles["inWindowFilter"]}>
              {new Intl.DateTimeFormat([], {
                month: "short",
                day: "2-digit",
                year: "numeric",
              }).format(new Date(iqUpgrade.Ts))}
            </label>
          </label>
        </div>
      ))}
    </div>
  );
};
