import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { apiInstance } from "../api/api-instance";
import { ReportProgramPatient } from "../api/data-contracts";
import moment from "moment";
import { ApiError } from "./patientSlice";

interface ProgramState {
  programData?: ReportProgramPatient;
  loading: boolean;
  error?: ApiError;
}

// Define the initial state using that type
const initialState: ProgramState = {
  loading: false,
};

export const loadProgramData = createAsyncThunk<
  ReportProgramPatient,
  { startDate: Date; endDate: Date },
  { state: RootState }
>("program/loadProgramData", async ({ startDate = null, endDate = null }, { getState, rejectWithValue }) => {
  const {
    patient: { selectedDevice },
  } = getState();
  if (selectedDevice?.stimEid) {
    if (null != startDate && null != endDate) {
      // startDate: always set to start of day
      startDate = moment(startDate).startOf("day").toDate();
      // endDate:
      // If it is Today, set current date
      // If it is Not Today, set end of day.
      var isCurrentDate = moment(endDate).isSame(new Date(), "day");
      if (isCurrentDate) {
        endDate = new Date();
      } else {
        endDate = moment(endDate).endOf("day").toDate();
      }
    }

    try {
      return await apiInstance.reportProgramGetProgramReportValues("1", {
        StimulatorEid: selectedDevice.stimEid,
        // send in UTC DateTime
        StartDate: startDate?.toISOString(),
        EndDate: endDate?.toISOString(),
      });
    } catch (error: any) {
      if (error.status === 401) {
        return rejectWithValue({ type: "token expired", message: "Token Expired" });
      } else {
        // Get error message from response if available
        const message = error.error?.Message || error.message;
        return rejectWithValue({ type: "error", message });
      }
    }
  }
});

export const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadProgramData.pending, state => {
      state.loading = true;
    });
    builder.addCase(loadProgramData.fulfilled, (state, action) => {
      state.loading = false;
      state.programData = action.payload;
    });
    builder.addCase(loadProgramData.rejected, (state, action) => {
      const error = action.payload as ApiError;
      return {
        ...initialState,
        error,
      };
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const selectProgramReport = (state: RootState) => state.programReport;
