import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";
import { patientSlice } from "./patientSlice";
import { bipoleSlice } from "./bipoleSlice";
import { therapySlice } from "./therapySlice";
import { programSlice } from "./programSlice";
import { impedanceSlice } from "./impedanceSlice";
import { configSlice } from "./configSlice";

const rootReducer = combineReducers({
  patient: patientSlice.reducer,
  bipoleReport: bipoleSlice.reducer,
  therapyReport: therapySlice.reducer,
  programReport: programSlice.reducer,
  impedanceReport: impedanceSlice.reducer,
  config: configSlice.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: true,
  });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
