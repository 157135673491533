import { Relaunch } from "./Relaunch";

interface Props {
  tokenExpired: boolean;
  message: React.ReactNode;
  description?: React.ReactNode;
}
export const ErrorComponent = ({ message, description, tokenExpired }: Props) => {
  if (tokenExpired) {
    return <Relaunch />;
  } else {
    return (
      <div>
        <h3>Sorry, an error occurred.</h3>
        <p style={{ marginTop: "1rem" }}>
          You can try to{" "}
          <a
            href="refresh"
            onClick={event => {
              event.preventDefault();
              window.location.reload();
            }}
          >
            refresh
          </a>{" "}
          this page and try again.
          <br />
          If refreshing doesn't work, please close this page and reopen from Salesforce.
        </p>
        <div style={{ marginTop: "2rem", fontSize: "0.8rem" }}>
          Error details:
          <br />
          Page Url: {window.location.href}
          <br />
          Error: {message}
          <pre>{description}</pre>
        </div>
      </div>
    );
  }
};
