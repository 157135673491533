import { LeadType } from "./LeadDiagram";
import styles from "./LeadDiagram.module.scss";

interface LeadProps {
  leadNumber: 1 | 2;
  leadType: LeadType;
  //vertebralIndex: number;
  topOffsetPercent: number;
}

export const Lead = ({ leadType, leadNumber, topOffsetPercent }: LeadProps) => {
  let position: "left" | "right" | "paddle";
  let image: string;
  let className: string;
  switch (leadType) {
    case LeadType.Double:
    case LeadType.DoubleSwapped:
    case LeadType.Other:
    case LeadType.Single:
      image = "/images/LeadDiagram/perc/perc-distal.svg";
      className = styles["perc-distal"];
      if (leadType === LeadType.Single && leadNumber === 2) {
        return null;
      } else {
        position = leadNumber === 1 ? "left" : "right";
        if (leadType === LeadType.DoubleSwapped) {
          position = position === "left" ? "right" : "left";
        }
      }
      break;
    case LeadType.Paddle:
    case LeadType.Surgical5x2:
      if (leadNumber === 2) {
        return null;
      } else {
        image = "/images/LeadDiagram/paddle/paddle-distal.svg";
        className = styles["paddle-distal"];
        position = "paddle";
      }
      break;
  }
  return (
    <div className={`${styles.lead} ${styles[position]}`} style={{ top: `${topOffsetPercent}%` }}>
      <img className={className} alt="" src={image} />
      {leadType !== LeadType.Paddle && leadType !== LeadType.Surgical5x2 && <img alt="" style={{marginTop:"-.8rem"}} src={`/images/LeadDiagram/perc/perc-proximal.svg`} />}
    </div>
  );
};
