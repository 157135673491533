import React from 'react'
// import { Rectangle } from 'recharts';
import BipoleLeadType from './BipoleLeadType';
import Rectangle from "../Rectangle.png";

const BipoleLeadTable:React.FC<BipoleLeadType> = ({elements}) => {

  return (
    <div style={{ float: "left" }}>
    <table
      className="table table_small"
    >
      <thead>
        <tr>
          <th style = {{lineHeight:"15px"}} colSpan={2} className="text-center table_head">
            <small style={{fontSize:"12px"}}>Electrode <br></br> Pair</small>
          </th>
        </tr>
      </thead>
      <tbody>
        {elements.map((element: any, index: any) => {
          return (
            <tr key={"elec-" + element + "-" + index}>
              <td className="pull-right">
                <div className='LeadTableMain'>
                <div
                  className="data"
                  style={{
                    width: "70px",
                    position: "relative",
                  }}
                >
                  <div
                    className={element.className0}
                    style={{ width: "50%" }}
                  >
                    {element.lead0}
                  </div>
                  <div
                    className={element.className1}
                    style={{ width: "50%" }}
                  >
                    {element.lead1}
                  </div>
                  
                </div>
                <img className = "RectImg" src={Rectangle} alt = "ggg"></img>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
  )
}

export default BipoleLeadTable