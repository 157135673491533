import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "./redux/hooks";
import { loadConfig, selectConfig } from "./redux/configSlice";
import { Loading } from "./components/Loading";
import { Rum } from "./Rum";

/** Container that fetches the config.json into redux if it is not already there.
 * Renders a spinner until the data has been loaded */
export const RequireConfig: React.FC<any> = ({ children }) => {
  const config = useSelector(selectConfig);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!config.apiBaseUrl && !config.error) {
      // The apiInstance's baseUrl will be set in loadConfig
      dispatch(loadConfig());
    }
  }, [config.apiBaseUrl, config.error, dispatch]);

  if (config.error) {
    return (
      <div>
        <h3>An error occurred getting the app configuration.</h3>
        <p>Please close this page and navigate to the portal from Salesforce.</p>
      </div>
    );
  } else {
    return config.apiBaseUrl ? (
      <>
        <Rum />
        {children}
      </>
    ) : (
      <Loading />
    );
  }
};
