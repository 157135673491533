interface VertebraInfo {
  name: string;
  height: number;
}

export const vertebralColumnConstants: { vertabrae: VertebraInfo[] } = {
  vertabrae: [
    { name: "C1", height: 18 },
    { name: "C2", height: 18 },
    { name: "C3", height: 18 },
    { name: "C4", height: 18 },
    { name: "C5", height: 18 },
    { name: "C6", height: 18 },
    { name: "C7", height: 18 },
    { name: "T1", height: 19.25 },
    { name: "T2", height: 20 },
    { name: "T3", height: 20 },
    { name: "T4", height: 20.25 },
    { name: "T5", height: 20.25 },
    { name: "T6", height: 21 },
    { name: "T7", height: 22.5 },
    { name: "T8", height: 22.5 },
    { name: "T9", height: 23.75 },
    { name: "T10", height: 24.5 },
    { name: "T11", height: 25.5 },
    { name: "T12", height: 26 },
    { name: "L1", height: 25 },
    { name: "L2", height: 25.5 },
    { name: "L3", height: 25.5 },
    { name: "L4", height: 26.5 },
    { name: "L5", height: 28.5 },
  ],
};

export default vertebralColumnConstants; 
