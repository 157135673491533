import { ReactComponent as RelaunchIcon } from "./images/relaunch.svg";
import styles from "./Relaunch.module.scss";

export const Relaunch = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <RelaunchIcon />
        <h2>Your session has expired.</h2>
        <p>Please close this tab, return to Salesforce, and relaunch the web portal.</p>
      </div>
    </div>
  );
};
