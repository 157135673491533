import React, { useRef, useState, useLayoutEffect, useCallback } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import styles from "./TherapyReport.module.scss";
import { Space, Checkbox } from "antd";
import Moment from "moment";
import HFXCloudAssessment from "../images/TherapyImages/HFXCloudAssessment.svg";
import TherapyOff from "../images/TherapyImages/TherapyOff.svg";
import LowBattery from "../images/TherapyImages/LowBattery.svg";
import InWindow from "../images/TherapyImages/InWindow.svg";
import OutsideWindow from "../images/TherapyImages/OutsideWindow.svg";
import PianArea1 from "../images/TherapyImages/LowerBack.svg";
import PainArea2 from "../images/TherapyImages/RightLeg.svg";
import OverallRelief from "../images/TherapyImages/OverallRelief.svg";
import PainArea3 from "../images/TherapyImages/Face.svg";
import PainArea4 from "../images/TherapyImages/PainArea4.svg";
import PainArea5 from "../images/TherapyImages/PainArea5.svg";
import { ReactComponent as NoData } from "../images/no-data-time-period.svg";
import { DateRange, ReportContainer } from "../Report/ReportContainer";
import { ReportTherapyDevice } from "../../api/data-contracts";
import { useIqUpgradeDataPoints } from "../../useIqUpgradePoints";
import { iqUpgradeColors } from "../ProgramsReport/ProgramsReport";
import { IqUpgradeFilters } from "../ProgramsReport/IqUpgradeFilters";

var strokeColours: string[] = ["#F5A048", "#4CB9DD", "#846DC9", "#E4A6F3", "#DDF6B1"];

export interface TherapyReportProps {
  loading: boolean;
  reportDate: Date;
  deviceTherapyData: ReportTherapyDevice;
  refreshReportData: () => void;
  onDateRangeChanged: (newDateRange: DateRange) => void;
  dateRange: DateRange;
  dateRangeLimits: DateRange;
}

export const TherapyReport = ({
  deviceTherapyData,
  reportDate,
  refreshReportData,
  dateRange,
  dateRangeLimits,
  onDateRangeChanged,
}: TherapyReportProps) => {
  const refreshedAt = new Date(reportDate);
  var inWindowStTime = null;
  var inWindowEndTime = null;
  // We are only fetching the selected device so it will be the first one
  // const deviceTherapyData = therapyData.RptThpyDevs![0];

  if (deviceTherapyData !== null) {
    var evalTimeStArray = deviceTherapyData.EvalWinStTime.split(":");
    inWindowStTime = evalTimeStArray[0] + ":" + evalTimeStArray[1];
    var evalTimeEndArray = deviceTherapyData.EvalWinEndTime.split(":");
    inWindowEndTime = evalTimeEndArray[0] + ":" + evalTimeEndArray[1];
    var painRegionsCount: string[] = deviceTherapyData.PRegions;
  }

  const [seriesIndex, setSeriesIndex] = useState(null);

  const initialSeriesArray = Array(painRegionsCount.length + 6).fill(2);
  const [checkedState, setCheckedState] = useState(new Array(initialSeriesArray.length).fill(true));

  const { iqUpgradeDataPoints } = useIqUpgradeDataPoints(deviceTherapyData.ActiveTherapyPrograms);

  const [iqUpgradesCheckedStates, setIqUpgradesCheckedStates] = useState(
    iqUpgradeDataPoints.map(_ => true)
  );

  var dataPoints = [
    "LowBatDPs",
    "ThpyOffDPs",
    "PRegionsDPs",
    "PRegions",
    "OverallPReliefDPs",
    "InWinDPs",
    "OutWinDPs",
    "HfxDPs",
  ] as const;
  var dataPointsExists: any = [];
  dataPoints.forEach(property => {
    if (deviceTherapyData[property].length === 0) {
      dataPointsExists.push(false);
    } else {
      dataPointsExists.push(true);
    }
  });

  const noData = dataPointsExists.every((dp: any) => dp === false);

  const chartRef = useRef(null);

  // Component did mount
  useLayoutEffect(() => {
    if (!noData) {
      setCheckedState(new Array(initialSeriesArray.length).fill(true));
      const tempData: any = deviceTherapyData;
      const tempDataPregions = deviceTherapyData.PRegionsDPs;
      let root = am5.Root.new("chartdivTherapy");

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          layout: root.horizontalLayout,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          paddingTop: 40,
        })
      );

      // Create Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0,
          max: 100,
          strictMinMax: true,
        })
      );

      yAxis.axisHeader.children.push(
        am5.Label.new(root, {
          text: "Overall Relief, %",
          fill: am5.Color.fromAny("#757982"),
          x: -60,
        })
      );
      yAxis.axisHeader.get("background").setAll({
        fillOpacity: 0,
      });
      yAxis.axisHeader.set("height", 40);
      chart.set("paddingTop", 35);

      yAxis.set("numberFormat", "#");

      //Second Y axis

      var Y2AxisRenderer = am5xy.AxisRendererY.new(root, { opposite: true });
      var Y2Axis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: Y2AxisRenderer,
          min: 0,
          max: 10,
          strictMinMax: true,
        })
      );
      Y2Axis.axisHeader.children.push(
        am5.Label.new(root, {
          text: "NRS",
          fill: am5.Color.fromAny("#757982"),
          x: am5.p100,
        })
      );
      Y2Axis.axisHeader.get("background").setAll({
        fillOpacity: 0,
      });
      Y2Axis.axisHeader.set("height", 40);

      // Create X-Axis
      var xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.1,
          baseInterval: {
            timeUnit: "second",
            count: 1,
          },

          renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 60 }),
        })
      );

      // chart/scrollbars/
      var scrollbar = chart.set(
        "scrollbarX",
        am5xy.XYChartScrollbar.new(root, {
          orientation: "horizontal",
          height: 60,
        })
      );
      chart.bottomAxesContainer.children.push(scrollbar);

      var sbDateAxis = scrollbar.chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.1,
          baseInterval: {
            timeUnit: "second",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {}),
        })
      );

      var sbValueAxis = scrollbar.chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      // Date formats for xaxis
      xAxis.get("dateFormats")["day"] = "dd MMM";
      xAxis.get("periodChangeDateFormats")["day"] = "dd MMM YYYY";

      const onTimeOffTimeData = (value: number) => {
        let timeSecond = 1000;
        let timeMinute = 60 * 1000;
        let milliseconds = " msec";
        let seconds = " sec";
        let minutes = " min";
        if (value < timeSecond) return value + milliseconds;
        else if (value < timeMinute) return value / timeSecond + seconds;
        else return value / timeMinute + minutes;
      };

      //tooltip preprocessmethod
      const tooltipPreprocess = (seriesData: []) => {
        let actLevelArr = ["Undefined", "Increase", "Decrease", "Same", "NA"];
        let medLevelArr = [
          "Undefined",
          "Least",
          "Decrease",
          "NoChange",
          "Increase",
          "Most",
          "NotApplicable",
        ];

        let followUps = tempData["FollowUpAssessments"];
        let activeTherapyPrograms = tempData["ActiveTherapyPrograms"];

        var newSeriesArr: any = [];
        seriesData.forEach((object: any) => {
          var object1: any = {};
          object1.timeStamp = Moment(object.Ts).format("DD-MMM-YYYY hh:mm A");

          var foundActive: any = activeTherapyPrograms.find(function (active: any) {
            return active.Ts === object.ThpyTs;
          });

          var foundfollowUp: any = followUps.find(function (followUp: any) {
            return followUp.Ts === object.Ts;
          });

          if (foundfollowUp !== undefined && foundfollowUp !== null) {
            object1.recommended = foundfollowUp.Recom;
            object1.useraction = foundfollowUp.UserAct;
            object1.oPainRelief = foundfollowUp.OPainRelief + "%";
            object1.actMedLev =
              actLevelArr[foundfollowUp.ImpFunc] + "/" + medLevelArr[foundfollowUp.MedLev];
          }
          object1.activeAreaHTML = [];

          if (foundActive !== undefined && foundActive !== null) {
            if (foundActive.ThpyPgm !== null && foundActive.ThpyPgm !== undefined) {
              object1.pgName = foundActive.ThpyPgm.Name;
              var startDate = Moment(Moment(object.ThpyTs));

              var endDate = Moment(Moment(object.Ts));

              var duration = Moment.duration(endDate.diff(startDate));

              var days = duration.days();

              var hours = duration.hours();

              object1.activeDays = days;
              object1.activeHours = hours;

              var activeArea = foundActive.ThpyPgm.ThpyAreas;

              if (foundActive.IsThpyOn === true) {
                activeArea.forEach((activeArea: any, activeAreaIndex: number) => {
                  var freqElectr =
                    activeArea.Elctrds[0].Idx +
                    1 +
                    activeArea.Elctrds[0].Pol +
                    "/" +
                    (activeArea.Elctrds[1].Idx + 1) +
                    activeArea.Elctrds[1].Pol;

                  var onTimeString = onTimeOffTimeData(activeArea.OnTime);
                  var offTimeString = onTimeOffTimeData(foundActive.ThpyPgm.OffTime);

                  object1.activeAreaHTML.push(
                    '<table style="display: inline-block; background-color:white"><tr style = "font-weight:bold"><td>A' +
                      (activeAreaIndex + 1) +
                      '</td></tr><tr style = "border-bottom: 1px solid grey;"><td style= "background-color: white;">' +
                      freqElectr +
                      'Freq</td><td style= "background-color: white; text-align: right"><b>' +
                      activeArea.Freq +
                      ' Hz</b></td></tr><tr style = "border-bottom: 1px solid grey;"><td style= "background-color: white;">PW</td><td style= "background-color: white; text-align: right"><b>' +
                      activeArea.PW +
                      ' usec</b></td></tr><tr style = "border-bottom: 1px solid grey;"><td style= "background-color: white;">On Time</td><td style= "background-color: white; text-align: right"><b>' +
                      onTimeString +
                      '</b></td></tr><tr style = "border-bottom: 1px solid grey;"><td style= "background-color: white;">Off Time</td><td style= "background-color: white; text-align: right"><b>' +
                      offTimeString +
                      '</b></td></tr><tr style = "border-bottom: 1px solid grey;"><td style= "background-color: white;">Min.Amp</td><td style= "background-color: white; text-align: right"><b>' +
                      activeArea.AmpMin.toFixed(2) +
                      ' mA</b></td></tr><tr style = "border-bottom: 1px solid grey;"><td style= "background-color: white;">Max.Amp</td><td style= "background-color: white;text-align: right"><b>' +
                      activeArea.AmpMax.toFixed(2) +
                      ' mA</b></td></tr><tr style = "border-bottom: 1px solid grey;"><td style= "background-color: white;">Start.Amp</td><td style= "background-color: white; text-align: right"><b>' +
                      activeArea.AmpSt.toFixed(2) +
                      ' mA</b></td></tr><tr style = "border-bottom: 1px solid grey;"><td style= "background-color: white;">Current.Amp</td><td style= "background-color: white; text-align: right"><b>' +
                      activeArea.AmpCurrent.toFixed(2) +
                      ' mA</b></td></tr><tr style = "border-bottom: 1px solid grey;"><td style= "background-color: white;">Step Size</td><td style= "background-color: white;text-align: right"><b>' +
                      activeArea.StepSz.toFixed(2) +
                      " mA</b></td></tr></table>"
                  );
                });
              }
            } else {
              object1.activeAreaHTML.push("Therapy Off");
            }
          } else {
            // Show No data
          }

          var newObj = { ...object, object1 };
          newSeriesArr.push(newObj);
        });
        return newSeriesArr;
      };

      // Electrode series

      //OverAll Painrelief series
      let overAllPainReliefData = tempData["OverallPReliefDPs"];
      var series1 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "OverAll % Relief",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "Val",
          valueXField: "Ts",
          stroke: am5.color("#689F38"),
        })
      );

      var sbSeries1 = scrollbar.chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "overAll",
          valueYField: "Val",
          valueXField: "Ts",
          xAxis: sbDateAxis,
          yAxis: sbValueAxis,
        })
      );

      series1.strokes.template.setAll({
        strokeWidth: 3,
      });

      var seriesRangeDataItem = yAxis.makeDataItem({ value: 50, endValue: 0 });
      var seriesRange = series1.createAxisRange(seriesRangeDataItem);
      seriesRange.fills.template.setAll({
        visible: true,
        opacity: 0.3,
      });

      seriesRange.fills.template.set("fill", am5.color("#509F33"));
      seriesRange.strokes.template.set("stroke", am5.color("#509F33"));

      seriesRangeDataItem.get("grid").setAll({
        strokeOpacity: 1,
        visible: true,
        stroke: am5.color("#509F33"),
        strokeDasharray: [8, 4],
      });

      sbSeries1.data.setAll(overAllPainReliefData);
      series1.data.setAll(overAllPainReliefData);

      //Y2 axis series
      tempData.PRegions.forEach((pregionElement: any, pregionIndex: number) => {
        let series1 = chart.series.push(
          am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: Y2Axis,
            valueYField: "Val",
            valueXField: "Ts",
            calculateAggregates: true,
            stroke: am5.color(strokeColours[pregionIndex]),
          })
        );

        var sbSeries = scrollbar.chart.series.push(
          am5xy.LineSeries.new(root, {
            valueYField: "Val",
            valueXField: "Ts",
            xAxis: sbDateAxis,
            yAxis: sbValueAxis,
          })
        );

        sbSeries.data.setAll(tempDataPregions[pregionIndex]);
        series1.data.setAll(tempDataPregions[pregionIndex]);

        series1.bullets.push(function (root) {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 4,
              fill: series1.get("stroke"),
            }),
          });
        });
      });

      var seriesArrayPlot = ["InWinDPs", "OutWinDPs", "HfxDPs", "LowBatDPs", "ThpyOffDPs"];

      seriesArrayPlot.forEach((seriesName: any) => {
        var inWindowData = tempData[seriesName];
        var newInwindowArr: any = null;
        var name = "";
        var bulletColor: string = "";
        if (seriesName === "InWinDPs") {
          name = "In Window";
          bulletColor = "#509F33";
          newInwindowArr = tooltipPreprocess(inWindowData);
        } else if (seriesName === "OutWinDPs") {
          name = "Out Window";
          bulletColor = "#F2A900";
          newInwindowArr = tooltipPreprocess(inWindowData);
        } else if (seriesName === "HfxDPs") {
          name = "HFX Cloud Assessment";
          bulletColor = "#FFDD05";
          newInwindowArr = tooltipPreprocess(inWindowData);
        } else if (seriesName === "LowBatDPs") {
          bulletColor = "#FC4D0F";
          newInwindowArr = inWindowData;
        } else {
          bulletColor = "#646566";
          newInwindowArr = inWindowData;
        }
        let tooltipInWindow = null;
        if (seriesName !== "ThpyOffDPs" && seriesName !== "LowBatDPs") {
          tooltipInWindow = am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            getFillFromSprite: false,
            getStrokeFromSprite: false,
            autoTextColor: false,
            getLabelFillFromSprite: true,
            labelHTML: `<div class="${styles["tooltip-container"]}">
            <table>
              <tbody>
                <tr>
                  <td><b>${name}</b></td>
                </tr> 
                <tr class="${styles["tooltip-content-row"]}">
                  <td>{object1.timeStamp}</td>
                </tr> 
                <tr class="${styles["tooltip-content-row"]}">
                  <td>Program Name</td>
                  <td>{object1.pgName}</td>
                </tr>
                <tr class="${styles["tooltip-content-row"]}">
                  <td>Current Pain Relief</td>
                  <td>{object1.oPainRelief}</td>
                </tr>
                <tr class="${styles["tooltip-content-row"]}">
                  <td>Active Days</td>
                  <td>{object1.activeDays}</td>
                </tr>
                <tr class="${styles["tooltip-content-row"]}">
                  <td>Active Hours</td>
                  <td>{object1.activeHours}</td>
                </tr>
                <tr class="${styles["tooltip-content-row"]}">
                  <td>Activity/Med</td>
                  <td>{object1.actMedLev}</td>
                </tr>
                <tr class="${styles["tooltip-content-row"]}">
                  <td>Recommended</td>
                  <td>{object1.recommended}</td>
                </tr>
                <tr class="${styles["tooltip-content-row"]}">
                  <td>User Action</td>
                  <td>{object1.useraction}</td>
                </tr>
              </tbody>
            </table>
            {object1.activeAreaHTML}
          </div>
          `,
          });

          tooltipInWindow.get("background").setAll({
            fill: am5.color(0xffffff),
            fillOpacity: 0.8,
            stroke: am5.color(0x000000),
          });
        }

        let series2 = chart.series.push(
          am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "Val",
            valueXField: "Ts",
            tooltipText: "Val",
            showTooltipOn: "hover",
            tooltip: tooltipInWindow,
          })
        );

        var sbSeries2 = scrollbar.chart.series.push(
          am5xy.LineSeries.new(root, {
            valueYField: "Val",
            valueXField: "Ts",
            xAxis: sbDateAxis,
            yAxis: sbValueAxis,
          })
        );

        sbSeries2.data.setAll(newInwindowArr);
        series2.data.setAll(newInwindowArr);

        series2.strokes.template.set("strokeOpacity", 0);
        sbSeries2.strokes.template.set("strokeOpacity", 0);
        if (seriesName === "HfxDPs") {
          var starTemplate = am5.Template.new({});
          series2.bullets.push(function () {
            var graphics = am5.Star.new(root, {
              fill: am5.color(bulletColor),
              spikes: 5,
              innerRadius: am5.percent(30),
            });
            return am5.Bullet.new(root, {
              sprite: graphics,
            });
          });
          series2.set("heatRules", [
            {
              target: starTemplate,
              min: 3,
              max: 50,
              dataField: "value",
              key: "radius",
            },
          ]);
        } else if (seriesName === "LowBatDPs" || seriesName === "ThpyOffDPs") {
          var starTemplate1 = am5.Template.new({});
          series2.bullets.push(function () {
            var graphics = am5.Star.new(root, {
              fill: am5.color(bulletColor),
              fillOpacity: 0.5,
              spikes: 4,
              innerRadius: am5.percent(70),
              centerY: am5.p100,
              radius: 8,
            });
            return am5.Bullet.new(root, {
              sprite: graphics,
            });
          });

          // Add heat rule
          series2.set("heatRules", [
            {
              target: starTemplate1,
              min: 3,
              max: 50,
              dataField: "value",
              key: "radius",
            },
          ]);
        } else {
          series2.bullets.push(function (root) {
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                radius: 5,
                fill: am5.color(bulletColor),
              }),
            });
          });
        }
      });

      // iq upgrade events
      iqUpgradeDataPoints.forEach((iqDataPoint, index) => {
        var iqRange = xAxis.createAxisRange(
          xAxis.makeDataItem({
            value: iqDataPoint.Ts,
          })
        );

        const label = `iQ ${iqDataPoint.Val.toFixed(1)} Upgrade`;
        const color = am5.Color.fromString(iqUpgradeColors[index]);
        iqRange.get("grid").setAll({
          forceHidden: false,
          strokeOpacity: 0.9,
          stroke: color,
          tooltipText: `[bold]${label}[/]\n{value.formatDate('dd MMM YYYY hh:mm a')}`,
        });

        iqRange.set(
          "bullet",
          am5xy.AxisBullet.new(root, {
            sprite: am5.Label.new(root, {
              fill: color,
              fontSize: 16,
              centerY: 23,
              centerX: 17.25,
              text: "▲",
            }),
          })
        );

        var iqLabel = iqRange.get("label");

        iqLabel.setAll({
          centerY: am5.p100,
          centerX: -12,
          rotation: -90,
          isMeasured: false,
          fontSize: 13,
          text: label,
          fontWeight: "500",
        });
      });

      // Add cursor
      var allseries = chart.series.values;
      chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          snapToSeries: [
            allseries[painRegionsCount.length + 1],
            allseries[painRegionsCount.length + 2],
            allseries[painRegionsCount.length + 3],
          ],
        })
      );
      chartRef.current = chart;
      return () => {
        root.dispose();
      };
    }
  }, [deviceTherapyData, initialSeriesArray.length, iqUpgradeDataPoints, noData, painRegionsCount]);

  // This code will only run when props.data changes
  useLayoutEffect(() => {
    if (!noData) {
      if (seriesIndex !== null) {
        checkedState.forEach((chck: boolean, chckIndex: number) => {
          var series = chartRef.current.series.getIndex(chckIndex);

          if (checkedState[chckIndex] === true) {
            series.show();
          } else {
            series.hide();
          }
        });
      }

      // show/hide the iq upgrade
      iqUpgradesCheckedStates.forEach((checked, index) => {
        const iqRange = chartRef.current.xAxes.getIndex(0).axisRanges.getIndex(index);
        iqRange.get("label").set("visible", checked);
        iqRange.get("grid").set("visible", checked);
        iqRange.get("bullet").get("sprite").set("visible", checked);
      });
    }
  }, [checkedState, seriesIndex, noData, iqUpgradesCheckedStates]);

  const handleOnSeriesCheckBoxClicked = (position: number) => {
    setSeriesIndex(position);
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };

  const handleClearFilters = useCallback(() => {
    onDateRangeChanged({ startDate: null, endDate: null });
    setCheckedState(new Array(initialSeriesArray.length).fill(true));
  }, [initialSeriesArray.length, onDateRangeChanged]);

  var painRegionImages = [PianArea1, PainArea2, PainArea3, PainArea4, PainArea5];

  const reportContent = (
    <div className={styles["chart-area"]}>
      {noData ? (
        <div className={styles["no-data-container"]}>
          <NoData />
          <h2>No Data</h2>
          <p>
            There is no data for this patient in the selected time period. Please choose a different
            time period.
          </p>
        </div>
      ) : (
        <div
          id="chartdivTherapy"
          data-testid="chartdivTherapy"
          style={{ width: "100%", height: "100%" }}
        ></div>
      )}
    </div>
  );

  return (
    <ReportContainer
      onRefreshReportData={refreshReportData}
      lastReportRunDate={refreshedAt}
      onDateRangeChanged={onDateRangeChanged}
      dateRange={dateRange}
      dateRangeLimits={dateRangeLimits}
      onClearFilters={handleClearFilters}
      reportContent={reportContent}
      reportFilters={
        <Space className={styles["legendSeries"]} size={10} direction="vertical">
          <IqUpgradeFilters
            iqUpgradeDataPoints={iqUpgradeDataPoints}
            iqUpgradesCheckedStates={iqUpgradesCheckedStates}
            setIqUpgradesCheckedStates={setIqUpgradesCheckedStates}
          />

          <label className={styles["legendSubFilter"]}>Pain Area</label>
          <div className="checkbox">
            <Checkbox
              type="checkBox"
              value="Toggle Series #1"
              name="Over-All Pain Relief"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(0);
              }}
              checked={checkedState[0]}
            />
            <img className={styles["filterIcon"]} src={OverallRelief} alt="Heart"></img>
            <div className={styles["filterSeriesText"]}>Overall Relief</div>
          </div>
          {painRegionsCount.map((painElement: string, painIndex: number) => {
            return (
              <div key={`painRegion-${painIndex}`}>
                <Checkbox
                  type="checkBox"
                  value="Toggle Series #1"
                  name={painRegionsCount[painIndex]}
                  onClick={() => {
                    handleOnSeriesCheckBoxClicked(painIndex + 1);
                  }}
                  checked={checkedState[painIndex + 1]}
                />
                <img
                  className={styles["filterIcon"]}
                  src={painRegionImages[painIndex]}
                  alt="Heart"
                ></img>
                <div className={styles["filterSeriesText"]} data-testid="pain-region-name">
                  {painRegionsCount[painIndex]}
                </div>
              </div>
            );
          })}
          <label className={styles["legendSubFilter"]}>Valid Window</label>
          <div>
            <Checkbox
              type="checkBox"
              value="Toggle Series #2"
              name="In-Window"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(painRegionsCount.length + 1);
              }}
              checked={checkedState[painRegionsCount.length + 1]}
            />
            <img className={styles["filterIcon"]} src={InWindow} alt="Heart"></img>
            <label className={styles["inwindowLabel"]}>
              <div className={styles["filterSeriesText"]}>In Window</div>
              <label className={styles["inWindowFilter"]}>
                {" "}
                {"  " + inWindowStTime + "-" + inWindowEndTime}{" "}
              </label>
            </label>
          </div>
          <div>
            <Checkbox
              type="checkBox"
              value="Toggle Series #2"
              name="Out-Window"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(painRegionsCount.length + 2);
              }}
              checked={checkedState[painRegionsCount.length + 2]}
            />
            <img className={styles["filterIcon"]} src={OutsideWindow} alt="Heart"></img>
            <div className={styles["filterSeriesText"]}>{" Out-Window"}</div>
          </div>
          <label className={styles["legendSubFilter"]}>Types of assessments</label>
          <div>
            <Checkbox
              type="checkBox"
              value="Toggle Series #1"
              name="HFX Cloud Assessment"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(painRegionsCount.length + 3);
              }}
              checked={checkedState[painRegionsCount.length + 3]}
            />
            <img className={styles["filterIcon"]} src={HFXCloudAssessment} alt="Heart"></img>
            <div className={styles["filterSeriesText"]}>HFX Cloud Assessment</div>
          </div>
          <label className={styles["legendSubFilter"]}>Status</label>
          <div>
            <Checkbox
              type="checkBox"
              value="Toggle Series #1"
              name="Low Battery"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(painRegionsCount.length + 4);
              }}
              checked={checkedState[painRegionsCount.length + 4]}
            />
            <img className={styles["filterIcon"]} src={LowBattery} alt="Heart"></img>
            <div className={styles["filterSeriesText"]}>Low Battery</div>
          </div>
          <div>
            <Checkbox
              type="checkBox"
              value="Toggle Series #1"
              name="Therapy Off"
              onClick={() => {
                handleOnSeriesCheckBoxClicked(painRegionsCount.length + 5);
              }}
              checked={checkedState[painRegionsCount.length + 5]}
            />
            <img className={styles["filterIcon"]} src={TherapyOff} alt="Heart"></img>
            <div className={styles["filterSeriesText"]}>Therapy Off</div>
          </div>
        </Space>
      }
    />
  );
};
