import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import Select from "react-select";
import { DataPoint, ProgramElectrodes, ReportImpedanceDevice } from "../../api/data-contracts";
import { Checkbox, Divider, Radio, RadioChangeEvent, Space } from "antd";
import styles from "./ImpedanceReport.module.scss";
import { DateRange, ReportContainer } from "../Report/ReportContainer";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ReactComponent as LeadDash } from "../images/lead-dash.svg";
import { ReactComponent as NoData } from "../images/no-data-time-period.svg";

/** The names of the possible electrode properties returned in the api data */
const electrodeProperties = [
  { key: "IE01DPs", color: "#938DD5" },
  { key: "IE02DPs", color: "#FFAB91" },
  { key: "IE03DPs", color: "#4A148C" },
  { key: "IE04DPs", color: "#FFD600" },
  { key: "IE05DPs", color: "#00C853" },
  { key: "IE06DPs", color: "#F57F17" },
  { key: "IE07DPs", color: "#AA00FF" },
  { key: "IE08DPs", color: "#00B8D4" },
  { key: "IE09DPs", color: "#7CB342" },
  { key: "IE10DPs", color: "#FF5252" },
  { key: "IE11DPs", color: "#304FFE" },
  { key: "IE12DPs", color: "#00838F" },
  { key: "IE13DPs", color: "#42A5F5" },
  { key: "IE14DPs", color: "#F8BBD0" },
  { key: "IE15DPs", color: "#7FBFBF" },
  { key: "IE16DPs", color: "#F06292" },
] as const;

const mriProperties = [
  { key: "MRIEnterDPs", label: "Enter MRI", color: "#FC4D0F", symbol: "▲" },
  { key: "MRIExitDPs", label: "Exit MRI", color: "#004C9D", symbol: "▼" },
] as const;

/** The electrode information in a structure best suited for the front end */
interface ElectrodesData {
  electrodeName: string;
  leadNumber: number;
  electrodeNumber: number;
  data: DataPoint[];
  color: string;
}

export interface ImpedanceReportProps {
  loading: boolean;
  reportDate: Date;
  deviceImpedanceData: ReportImpedanceDevice;
  refreshReportData: () => void;
  onDateRangeChanged: (newDateRange: DateRange) => void;
  dateRange: DateRange;
  dateRangeLimits: DateRange;
}

export const ImpedanceReport = ({
  deviceImpedanceData,
  reportDate,
  refreshReportData,
  dateRange,
  dateRangeLimits,
  onDateRangeChanged,
}: ImpedanceReportProps) => {
  const refreshedAt = new Date(reportDate);
  // map the electrode data into a form suited for the front end.
  const electrodesData = useMemo(() => {
    const data: ElectrodesData[] = [];
    electrodeProperties.forEach(property => {
      const dataPoints = deviceImpedanceData[property.key];
      if (dataPoints) {
        const electrodeNumber = Number.parseInt(property.key.substring(2, 4));
        const leadNumber = electrodeNumber <= 8 ? 1 : 2;
        // The names should be ordered the same as the electrode properties so we can use the current length of the array we are building to get the electrode
        const electrodeName = deviceImpedanceData.ElctrdNames[data.length];
        const electrodeEntry = {
          electrodeName,
          electrodeNumber,
          leadNumber,
          data: dataPoints,
          color: property.color,
        };
        data.push(electrodeEntry);
      }
    });
    return data;
  }, [deviceImpedanceData]);

  var groupedData = useMemo(
    () =>
      electrodesData[0].data.map((dataPoint, index) => {
        return electrodesData.reduce(
          (acc, electrode) => {
            acc[electrode.electrodeName] = electrode.data[index].Val;
            return acc;
          },
          { ts: dataPoint.Ts } as { ts: number } & Record<string, number>
        );
      }),
    [electrodesData]
  );

  const noData = electrodesData.every(ed => ed.data.length === 0);

  const [selectedProgram, setSelectedProgram] = useState<ProgramElectrodes>();
  /** Represents the visibility of the electrodes in the filter (and will be synced to the graph).
   * The indexes line up with electrodesData. */
  const [leadVisibilities, setLeadVisibilities] = useState<boolean[]>(() =>
    electrodesData.map(e => true)
  );

  // Create and populate the chart
  const chartRef = useRef<am5xy.XYChart>();

  const buildConsolidatedTooltipHtml = useCallback(
    (chart: am5xy.XYChart) => {
      const htmlArray = [`<div class="${styles["tooltip-container"]}">`];
      chart.series.each((series, index) => {
        if (index < electrodesData.length && leadVisibilities[index]) {
          htmlArray.push(
            `<div class="${styles["tooltip-container-label"]}">
            <span style="color: ${series.get("stroke").toString()}">━</span> 
            ${series.get("name")}:
          </div>
          <div class="${styles["tooltip-container-value"]}">{${
              electrodesData[index].electrodeName
            }} Ohms</div>`
          );
        }
      });
      htmlArray.push("</div>");
      return htmlArray.join("");
    },
    [electrodesData, leadVisibilities]
  );

  useLayoutEffect(() => {
    if (!noData) {
      const root = am5.Root.new("chartdiv");
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          layout: root.horizontalLayout,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          maxTooltipDistance: -1,
        })
      );
      chartRef.current = chart;

      // Create Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );
      yAxis.axisHeader.children.push(
        am5.Label.new(root, {
          text: "Lead Impedance (Ohms)",
          fill: am5.Color.fromAny("#757982"),
          x: -60,
        })
      );
      yAxis.axisHeader.get("background").setAll({
        fillOpacity: 0,
      });
      yAxis.axisHeader.set("height", 40);
      chart.set("paddingTop", 35);

      // Create X-Axis
      var xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.1,
          baseInterval: {
            timeUnit: "second",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 60 }),
        })
      );

      // chart/scrollbars/
      var scrollbar = chart.set(
        "scrollbarX",
        am5xy.XYChartScrollbar.new(root, {
          orientation: "horizontal",
          height: 60,
        })
      );
      chart.bottomAxesContainer.children.push(scrollbar);

      var sbDateAxis = scrollbar.chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: {
            timeUnit: "second",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {}),
        })
      );

      var sbValueAxis = scrollbar.chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      // Date formats for xaxis
      xAxis.get("dateFormats")["day"] = "dd MMM";
      xAxis.get("periodChangeDateFormats")["day"] = "dd MMM YYYY";

      // Electrode series
      electrodesData.forEach((electrode, index) => {
        let mainSeries = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: electrode.electrodeName,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: electrode.electrodeName,
            valueXField: "ts",
            stroke: am5.Color.fromString(electrode.color),
            fill: am5.Color.fromString(electrode.color),
          })
        );
        const tooltip = mainSeries.set(
          "tooltip",
          am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getStrokeFromSprite: false,
            autoTextColor: false,
            pointerOrientation: "horizontal",
          })
        );

        tooltip.get("background").setAll({
          fill: am5.color(0xffffff),
          shadowOpacity: 0.6,
          shadowBlur: 7,
          shadowColor: am5.color("rgba(0, 42, 106, 0.2)"),
        });
        tooltip.label.setAll({
          html: "<div>{valueX.formatDate('dd MMM YYYY hh:mm a')}</div>",
          fill: am5.color(0x000000),
        });

        var scrollBarSeries = scrollbar.chart.series.push(
          am5xy.LineSeries.new(root, {
            valueYField: electrode.electrodeName,
            valueXField: "ts",
            xAxis: sbDateAxis,
            yAxis: sbValueAxis,
          })
        );

        scrollBarSeries.data.setAll(groupedData);
        mainSeries.data.setAll(groupedData);

        mainSeries.bullets.push(root =>
          am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 4,
              fill: mainSeries.get("fill"),
            }),
          })
        );
      });

      //MRI Enter and Exit series
      mriProperties.forEach(mriElement => {
        // Create series
        // Define data
        const mriDataPoints = deviceImpedanceData[mriElement.key];
        if (mriDataPoints) {
          mriDataPoints.forEach(mriDataPoint => {
            // add ranges
            var mriRange = xAxis.createAxisRange(
              xAxis.makeDataItem({
                value: mriDataPoint.Ts,
              })
            );

            mriRange.get("grid").setAll({
              forceHidden: false,
              strokeOpacity: 0.9,
              stroke: am5.Color.fromAny(mriElement.color),
              tooltipText: `[bold]${mriElement.label}[/]\n{value.formatDate('dd MMM YYYY hh:mm a')}`,
            });

            mriRange.set(
              "bullet",
              am5xy.AxisBullet.new(root, {
                sprite: am5.Label.new(root, {
                  fill: am5.Color.fromAny(mriElement.color),
                  fontSize: 16,
                  centerY: 23,
                  centerX: 17.25,
                  text: mriElement.symbol,
                }),
              })
            );

            var goalLabel = mriRange.get("label");

            goalLabel.setAll({
              centerY: am5.p100,
              centerX: -12,
              rotation: -90,
              isMeasured: false,
              fontSize: 13,
              text: mriElement.label,
              fontWeight: "500",
            });
          });
        }
      });

      // Add cursor
      chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          snapToSeries: chart.series.values,
        })
      );

      // cleanup (unmount)
      return () => {
        root.dispose();
      };
    }
  }, [deviceImpedanceData, electrodesData, groupedData, noData]);

  const handleProgramChange = useCallback(
    (newProgram: ProgramElectrodes) => {
      setSelectedProgram(newProgram);
      // Update the selected leads
      setLeadVisibilities(
        electrodesData.map(electrode =>
          newProgram.ENums.some(num => num === electrode.electrodeNumber)
        )
      );
    },
    [electrodesData]
  );

  type SelectedLead = 1 | 2 | "All" | null /** null = custom */;
  const selectedLead: SelectedLead = useMemo(() => {
    if (leadVisibilities.every(visible => visible)) {
      return "All";
    } else if (
      electrodesData.every(
        (electrode, index) => electrode.leadNumber !== 1 || leadVisibilities[index]
      )
    ) {
      return 1;
    } else if (
      electrodesData.every(
        (electrode, index) => electrode.leadNumber !== 2 || leadVisibilities[index]
      )
    ) {
      return 2;
    } else {
      return null;
    }
  }, [electrodesData, leadVisibilities]);

  // Sync the chart with the lead filters when visible electrode filters or selected program changes
  const updateChartLeadVisibilities = useCallback(() => {
    if (noData) {
      return;
    }
    // The chart series array should line up with the leadVisibilities, but the series will have additional items
    const newHtml = buildConsolidatedTooltipHtml(chartRef.current);

    chartRef.current.series.each((series, index) => {
      // Toggle the graph series
      if (index < leadVisibilities.length) {
        if (leadVisibilities[index]) {
          !series.isVisible() && series.show();
        } else {
          series.isVisible() && series.hide();
        }
        // Update tooltips
        const tooltip = series.get("tooltip");
        tooltip.label.adapters.remove("html");
        tooltip.label.adapters.add("html", function (html, target) {
          if (target && target.dataItem) {
            return `${html}${newHtml}`;
          } else {
            return html;
          }
        });
      }
    });
  }, [buildConsolidatedTooltipHtml, leadVisibilities, noData]);

  useEffect(() => {
    updateChartLeadVisibilities();
  }, [updateChartLeadVisibilities]);

  const leadButtons = [
    { label: "All", value: "All" },
    { label: "Lead 1", value: 1 },
    { label: "Lead 2", value: 2 },
  ];
  const handleLeadButtonChange = ({ target: { value } }: RadioChangeEvent) => {
    const newSelectedLead = value as SelectedLead;
    switch (newSelectedLead) {
      case "All":
        setLeadVisibilities(leadVisibilities.map(lv => true));
        break;
      case 1:
      case 2:
        setLeadVisibilities(electrodesData.map(e => e.leadNumber === newSelectedLead));
        break;
    }
    setSelectedProgram(null);
  };

  // Leads
  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    const index: number = event.target.value;
    const newLeadVisibilities = [...leadVisibilities];
    newLeadVisibilities[index] = !leadVisibilities[index];
    setLeadVisibilities(newLeadVisibilities);
    setSelectedProgram(null);
  };

  const leadCheckboxes = electrodesData.map((electrode, index) => ({
    leadNumber: electrode.leadNumber,
    checkbox: (
      <div key={electrode.electrodeNumber}>
        <Checkbox value={index} onChange={handleCheckboxChange} checked={leadVisibilities[index]}>
          <LeadDash stroke={electrode.color} strokeWidth="4" style={{ verticalAlign: "middle" }} />
          &nbsp;<span data-testid="electrode-name">{electrode.electrodeName}</span>
        </Checkbox>
      </div>
    ),
  }));

  const handleClearFilters = useCallback(() => {
    // Clear the date ranges
    onDateRangeChanged({ startDate: null, endDate: null });
    // Clear selected program
    setSelectedProgram(null);
    // Clear lead selection
    setLeadVisibilities(leadVisibilities.map(_ => true));
  }, [leadVisibilities, onDateRangeChanged]);

  const reportContent = (
    <div className={styles["chart-area"]}>
      {noData ? (
        <div className={styles["no-data-container"]}>
          <NoData />
          <h2>No Data</h2>
          <p>
            There is no data for this patient in the selected time period. Please choose a different
            time period.
          </p>
        </div>
      ) : (
        <div data-testid="chartdiv" id="chartdiv" style={{ width: "100%", height: "100%" }}></div>
      )}
    </div>
  );

  return (
    <ReportContainer
      onRefreshReportData={refreshReportData}
      lastReportRunDate={refreshedAt}
      onDateRangeChanged={onDateRangeChanged}
      dateRange={dateRange}
      dateRangeLimits={dateRangeLimits}
      onClearFilters={handleClearFilters}
      reportContent={reportContent}
      reportFilters={
        <Space direction="vertical" className={styles["spacer"]}>
          <Radio.Group
            options={leadButtons}
            optionType="button"
            buttonStyle="solid"
            onChange={handleLeadButtonChange}
            value={selectedLead}
            className={styles["lead-buttons"]}
          />
          <div>
            <div className={styles["lead-grid"]} data-testid="lead-grid">
              <div>
                Lead 1
                {leadCheckboxes
                  .filter(electrode => electrode.leadNumber === 1)
                  .map(item => item.checkbox)}
              </div>
              <div>
                Lead 2
                {leadCheckboxes
                  .filter(electrode => electrode.leadNumber === 2)
                  .map(item => item.checkbox)}
              </div>
            </div>
          </div>
          <div>
            Program
            <Select
              options={deviceImpedanceData.PgmElctrds}
              getOptionLabel={option => option.PgName}
              onChange={handleProgramChange}
              placeholder={"No Program Selected"}
              className="programFilter"
              value={selectedProgram}
            />
          </div>
          <Divider />
          <div>Legend</div>
          {mriProperties.map(mri => (
            <div key={mri.key}>
              <span style={{ color: mri.color, fontSize: "16px" }}>{mri.symbol}</span>{" "}
              <span style={{ color: "black" }}>{mri.label}</span>
            </div>
          ))}
        </Space>
      }
    />
  );
};
