import React, { useCallback, useState } from "react";
import "./BipoleReport.scss";
import { Button, DatePicker, Space, Divider, Select, Radio, RadioChangeEvent } from "antd";
import BipoleChartBody from "./BipoleChartBody";
import BipoleLeadTable from "./BipoleLeadTable";
import { BipoleAmplitudeTable } from "./BipoleAmplitudeTable";
import { BipoleLegend } from "./BipoleLegend";
import styles from "../Report/ReportContainer.module.scss";

import moment from "moment";
import { Overlay } from "react-portal-overlay";
import { ReactComponent as FilterIcon } from "../images/FilterImages/Filters.svg";
import { ReactComponent as RefreshIcon } from "../images/refresh.svg";
import { ReactComponent as ClearFiltersIcon } from "../images/clear-all.svg";
import { ReactComponent as NoData } from "../images/no-data-time-period.svg";
import { BipoleAmplitudePainRelief, ReportBipoleDevice } from "../../api/data-contracts";
import { DateRange } from "./BipoleReportContainer";

const { RangePicker } = DatePicker;
const { Option } = Select;

export interface BipoleReportProps {
  loading: boolean;
  reportDate: Date;
  deviceBipoleData: ReportBipoleDevice;
  refreshBipoleData: (newDateRange: DateRange) => void;
}

export const BipoleReport = ({
  deviceBipoleData,
  reportDate,
  refreshBipoleData,
}: BipoleReportProps) => {
  // For lead pair
  var elements: any[] = [];
  // Amplitude
  var ampArray: any[] = [];
  //For date picker
  var dateArray: any[] = [];
  // Program filter
  var programsArray: any[] = [];

  // min date and max date states
  const [maxDate, setMaxDate] = React.useState<Date>();
  const [minDate, setMinDate] = React.useState<Date>();

  //program index and lead filter states
  const [programIndex, setProgramIndex] = useState(0);
  const [clearAll, setClearAll] = useState(false);

  var startDateRange: any, endDateRange: any, minStartDate, maxEndDate;
  const [customButtonClicked, setCustomButtonClicked] = useState(false);

  const [leadFilter, setLeadFilter] = useState(0);
  // device state

  let leadType, leadOffset, leadOnTop, topLead;
  let bipoleArray: BipoleAmplitudePainRelief[];
  if (deviceBipoleData !== null && deviceBipoleData !== undefined) {
    startDateRange = deviceBipoleData.DtRngStTs;
    endDateRange = deviceBipoleData.DtRngEndTs;
    minStartDate = deviceBipoleData.DtRngMinTs;
    maxEndDate = deviceBipoleData.DtRngMaxTs;
    var deviceReport = deviceBipoleData;
    var sortedBipoles = deviceReport.VertebalClm.SortedBips;
    sortedBipoles.forEach((element: any) => {
      var obj: any = {};
      var leadOne = element[0] + 1;
      var leadTwo = element[1] + 1;
      obj["lead0"] = leadOne;
      obj["lead1"] = leadTwo;
      obj["className0"] = "darkgrey_data1 data_flow text-center";
      obj["className1"] = "darkgrey_data2 data_flow text-center";
      elements.push(obj);
    });

    var elements1: any = [...elements];
    var overLayBipoles = [
      "1-2",
      "9-10",
      "2-3",
      "10-11",
      "3-4",
      "11-12",
      "4-5",
      "12-13",
      "5-6",
      "13-14",
      "6-7",
      "14-15",
      "7-8",
      "15-16",
    ];

    bipoleArray = Object.values(
      deviceReport.BipAmpPReliefs.reduce((acc, cur) => {
        const key = `${cur.Amp}|${cur.BipIdx.join(",")}`;
        if (!acc[key]?.OPainRelief || acc[key].OPainRelief < cur.OPainRelief) {
          acc[key] = cur;
        }
        return acc;
      }, {} as { [key: string]: BipoleAmplitudePainRelief })
    );

    bipoleArray.sort((a: any, b: any) => (a.Amp > b.Amp ? 1 : -1));
    let filteredBipoleArray = bipoleArray.filter((bipole: any) => bipole["OPainRelief"] !== null);
    bipoleArray = filteredBipoleArray;

    bipoleArray.forEach((bipoleElement: any) => {
      var amplitude = bipoleElement.Amp;
      if (!ampArray.includes(amplitude)) {
        ampArray.push(amplitude);
      }
      dateArray.push(new Date(bipoleElement.AssessDt).setMilliseconds(0));
    });

    leadType = deviceReport.VertebalClm.LType;
    leadOffset = deviceReport.VertebalClm.LOff;
    var vertLeadOnTop = deviceReport.VertebalClm.LOnTop;
    if (vertLeadOnTop === 0) {
      leadOnTop = "Same";
    } else if (vertLeadOnTop === 1) {
      leadOnTop = "Lead 1";
    } else {
      leadOnTop = "Lead 2";
    }

    topLead = deviceReport.VertebalClm.TopLead;

    // HighLight electrodes for Program filter and Lead filter
    const changeElementsArrayMethod = (bArray: any) => {
      var foundIndexArray: any = [];

      bArray.forEach((bipoleE: any, bipIndex: any) => {
        var leadA = bipoleE.BipIdx[0] + 1;
        var leadB = bipoleE.BipIdx[1] + 1;

        var foundIndex = elements.findIndex(
          (ele: any) => ele.lead0 === leadA || ele.lead1 === leadB
        );

        foundIndexArray.push(foundIndex);
      });

      elements.forEach((ele: any, eleIndex: any) => {
        if (foundIndexArray.includes(eleIndex)) {
          let newElement = {
            ...elements[eleIndex],
            className0: "darkgrey_data1 data_flow text-center",
            className1: "darkgrey_data2 data_flow text-center",
          };
          elements[eleIndex] = newElement;
        } else {
          let newElement = {
            ...elements[eleIndex],
            className0: "darkgrey_dataNotShown data_flow text-center",
            className1: "darkgrey_dataNotShown data_flow text-center",
          };
          elements[eleIndex] = newElement;
        }
      });
    };

    const changeElementsArrayMethodForLead = () => {
      elements.forEach((ele: any, eleIndex: any) => {
        if (
          ele.lead0 >= 1 &&
          ele.lead0 <= 8 &&
          ele.lead1 >= 1 &&
          ele.lead1 <= 8 &&
          leadFilter === 1
        ) {
          let newElement = {
            ...elements[eleIndex],
            className0: "darkgrey_data1 data_flow text-center",
            className1: "darkgrey_data2 data_flow text-center",
          };
          elements[eleIndex] = newElement;
        } else if (
          ele.lead0 >= 9 &&
          ele.lead0 <= 16 &&
          ele.lead1 >= 9 &&
          ele.lead1 <= 16 &&
          leadFilter === 2
        ) {
          let newElement = {
            ...elements[eleIndex],
            className0: "darkgrey_data1 data_flow text-center",
            className1: "darkgrey_data2 data_flow text-center",
          };
          elements[eleIndex] = newElement;
        } else {
          let newElement = {
            ...elements[eleIndex],
            className0: "darkgrey_dataNotShown data_flow text-center",
            className1: "darkgrey_dataNotShown data_flow text-center",
          };
          elements[eleIndex] = newElement;
        }
      });
    };

    if (programIndex !== 0 || leadFilter !== 0) {
      //Lead Filter
      if (leadFilter !== 0) {
        var tempArrayLeadFilter: any = [];
        if (leadFilter === 1) {
          tempArrayLeadFilter = bipoleArray.filter(
            (k: any) => k.BipIdx[0] >= 0 && k.BipIdx[0] <= 7 && k.BipIdx[1] >= 0 && k.BipIdx[1] <= 7
          );
        }
        if (leadFilter === 2) {
          tempArrayLeadFilter = bipoleArray.filter(
            (k: any) =>
              k.BipIdx[0] >= 8 && k.BipIdx[0] <= 15 && k.BipIdx[1] >= 8 && k.BipIdx[1] <= 15
          );
        }

        bipoleArray = tempArrayLeadFilter;
        changeElementsArrayMethodForLead();
      }
      // program index
      if (programIndex !== 0) {
        var newArray = bipoleArray.filter((k: any) => k["PgName"] === programIndex);

        if (newArray != null) {
          changeElementsArrayMethod(newArray);
        }
      }
    }

    bipoleArray.sort((a: any, b: any) => (a.PgNum > b.PgNum ? 1 : -1));
    bipoleArray.forEach((bipoleElement: any) => {
      if (programsArray == null) {
        programsArray.push(bipoleElement.PgName);
      } else {
        if (!programsArray.includes(bipoleElement.PgName)) {
          programsArray.push(bipoleElement.PgName);
        }
      }
    });

    // assigning min and max date for date picker
    var minDateEnabled1 = new Date(Math.min(...dateArray));
    var maxDateEnabled1 = new Date(Math.max(...dateArray));

    if (minDate !== undefined || maxDate !== undefined) {
      let finalMinDate: any = null,
        finalMaxDate: any = null;

      if (minDate !== undefined && maxDate === undefined) {
        finalMinDate = minDate;
        finalMaxDate = maxDateEnabled1;
      } else if (minDate === undefined && maxDate !== undefined) {
        finalMaxDate = maxDate;
        finalMinDate = minDateEnabled1;
      } else {
        finalMinDate = minDate;
        finalMaxDate = maxDate;
      }

      finalMinDate = new Date(finalMinDate);
      finalMaxDate = new Date(finalMaxDate);

      if (finalMinDate.getTime() <= finalMaxDate.getTime()) {
        if (finalMinDate.getTime() === finalMaxDate.getTime()) {
          finalMaxDate.setHours(finalMaxDate.getHours() + 13);
          finalMaxDate.setMinutes(finalMaxDate.getMinutes() + 59);
        }

        let filteredBipoleArrayWithDate = bipoleArray.filter(
          (bipole: any) =>
            new Date(bipole["AssmtDt"]).getTime() <= finalMaxDate.getTime() &&
            new Date(bipole["AssmtDt"]).getTime() >= finalMinDate.getTime()
        );
        bipoleArray = filteredBipoleArrayWithDate;
        bipoleArray.sort((a: any, b: any) => (a.PgNum > b.PgNum ? 1 : -1));
      }
    }
  }

  const [applyClicked, setApplyClicked] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(elements.length).fill(false));
  const [checkedStateUI, setCheckedStateUI] = useState(new Array(elements.length).fill(false));
  const [customFilterCheckedArray, setCustomFilterCheckedArray] = useState([]);
  const [customFilterFoundIndexArray, setCustomFilterFoundIndexArray] = useState([]);
  var updatedCheckedState: any = [];
  var customFilterArray: any = [];
  // var customFilterFoundIndexArray: number[] = [];

  // handle check box change
  const handleOnChange = (position: any, elementBip: any) => {
    var splitBip = elementBip.split("-");
    customFilterArray.push(elementBip);

    overLayBipoles.forEach((element: any) => {
      var elementSplit = element.split("-");
      if (elementSplit[0] === splitBip[0] && elementSplit[1] === splitBip[1]) {
        updatedCheckedState = checkedStateUI.map((item, index) =>
          index === position ? !item : item
        );
        setCheckedStateUI(updatedCheckedState);
      }
    });

    elements.forEach((elementEle: any, elIndx: any) => {
      // var  elementSplit = element.split("-");

      if (
        elementEle.lead0.toString() === splitBip[0] &&
        elementEle.lead1.toString() === splitBip[1]
      ) {
        customFilterFoundIndexArray.push(elIndx);
      }
    });
  };

  const handleApplyButtonModalView = () => {
    setClearAll(false);
    setProgramIndex(0);
    setLeadFilter(0);
    setMinDate(undefined);
    setMaxDate(undefined);
    setOpen(false);
    setLeadValue("All");
    setCheckedState(checkedStateUI);
    setCustomButtonClicked(true);
    setCustomFilterCheckedArray(customFilterFoundIndexArray);
    setApplyClicked(true);
  };

  // Highlight electrodes for Custom Filter
  if (applyClicked === true) {
    elements.forEach((ele: any, eleIndex: any) => {
      if (customFilterFoundIndexArray.includes(eleIndex)) {
        let newElement = {
          ...elements[eleIndex],
          className0: "darkgrey_data1 data_flow text-center",
          className1: "darkgrey_data2 data_flow text-center",
        };
        elements1[eleIndex] = newElement;
      } else {
        let newElement = {
          ...elements[eleIndex],
          className0: "darkgrey_dataNotShown data_flow text-center",
          className1: "darkgrey_dataNotShown data_flow text-center",
        };
        elements1[eleIndex] = newElement;
      }
    });
  }
  //modal view state
  const [open, setOpen] = useState(false);
  // assigning min and max date for date picker

  const updateLeadFilters = useCallback(() => {
    setLeadValue("All");
  }, []);

  React.useEffect(() => {
    updateLeadFilters();
  }, [updateLeadFilters]);

  const handleProgramChange = (value: any) => {
    setLeadFilter(0);
    setMinDate(undefined);
    setMaxDate(undefined);
    setApplyClicked(false);
    setCheckedState(new Array(elements.length).fill(false));
    setCustomFilterFoundIndexArray([]);
    setProgramIndex(value);
    setCustomButtonClicked(false);
  };

  const onDateChange = (
    [startDate, endDate]: [moment.Moment, moment.Moment],
    dateString: [string, string]
  ) => {
    setCustomButtonClicked(false);

    if (
      startDate.toDate().getTime() >= startDateRange &&
      endDate.toDate().getTime() <= endDateRange
    ) {
      setMinDate(startDate.toDate());
      setMaxDate(endDate.toDate());
      setClearAll(true);
    } else {
      //Call Api with start and end date
      setProgramIndex(0);
      setLeadFilter(0);
      setMinDate(startDate.toDate());
      setMaxDate(endDate.toDate());
      setApplyClicked(false);
      setCheckedState(new Array(elements.length).fill(false));
      setCustomFilterFoundIndexArray([]);
      setProgramIndex(0);
      setClearAll(false);
      refreshBipoleData({ startDate: startDate.toDate(), endDate: endDate.toDate() });
    }
  };

  const disableDateRanges = (startDate: any, endDate: any) => {
    // const { startDate, endDate } = range;
    return function disabledDate(current: any) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck = current && current < moment(startDate, "YYYY-MM-DD");
      }
      if (endDate) {
        endCheck = current && current > moment(endDate, "YYYY-MM-DD");
      }
      return (startDate && startCheck) || (endDate && endCheck);
    };
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleToggleFilters = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen]);

  const optionsForLead = [
    { label: "All", value: "All" },
    { label: "Lead 1", value: "Lead 1" },
    { label: "Lead 2", value: "Lead 2" },
  ];

  const [leadValue, setLeadValue] = useState("All");

  const onChangeLead = ({ target: { value } }: RadioChangeEvent) => {
    setLeadValue(value);
    setCustomButtonClicked(false);
    if (value === "Lead 1") {
      let result = checkedState.every(element => element === false);
      if (!result) {
        // setProgramIndex(0);

        setMinDate(undefined);
        setMaxDate(undefined);
        setApplyClicked(false);
        setCheckedState(new Array(elements.length).fill(false));
        setCustomFilterFoundIndexArray([]);
        setLeadFilter(1);
      } else {
        setLeadFilter(1);
      }
    } else if (value === "Lead 2") {
      let result = checkedState.every(element => element === false);
      if (!result) {
        // setProgramIndex(0);

        setMinDate(undefined);
        setMaxDate(undefined);
        setApplyClicked(false);
        setCheckedState(new Array(elements.length).fill(false));
        setCustomFilterFoundIndexArray([]);
        setLeadFilter(2);
      } else {
        setLeadFilter(2);
      }
    } else {
      let result = checkedState.every(element => element === false);
      if (!result) {
        // setProgramIndex(0);

        setMinDate(undefined);
        setMaxDate(undefined);
        setApplyClicked(false);
        setCheckedState(new Array(elements.length).fill(false));
        setCustomFilterFoundIndexArray([]);
        setLeadFilter(0);
      } else {
        setLeadFilter(0);
      }
    }
  };

  return (
    <React.Fragment>
      <div className={styles["content"]}>
        <div className={styles["chart-column"]}>
          <div className={styles["chart-column-button"]}>
            <Button
              className={styles["refresh-button"]}
              icon={<RefreshIcon className={styles["button-icon"]} />}
              onClick={() => {
                setProgramIndex(0);
                setLeadFilter(0);
                setApplyClicked(false);
                setCheckedState(new Array(elements.length).fill(false));
                setCustomFilterFoundIndexArray([]);
                setCustomButtonClicked(false);
                setProgramIndex(0);
                refreshBipoleData({ startDate: minDate, endDate: maxDate });
                setClearAll(false);
                setLeadValue("All");
              }}
            >
              Last refreshed at {moment(reportDate).format("hh:mm:ss A")}
            </Button>
          </div>
          <div className={styles["chart-column-chart-bipole"]}>
            <div className="leadDiagram" style={{ padding: "5px" }}>
              <h4>Lead Type: {leadType}</h4>
              <h4>Top Lead: {topLead}</h4>
              <h4>Lead on top: {leadOnTop}</h4>
              <h4>Lead offset: {leadOffset}</h4>
            </div>
            <div className="chart-area">
              {" "}
              {bipoleArray.length > 0 ? (
                <div className="row" style={{ padding: "5px", width: "100%", height: "100%" }}>
                  <div
                    className="col-md-12"
                    // style={{  border: "2px solid #dadada" }}
                  >
                    <div className="amplitude">Amplitudes Tested (mA)</div>
                    <div className="text-right"> </div>
                    <div className="row">
                      {applyClicked === true ? (
                        <BipoleLeadTable elements={elements1} />
                      ) : (
                        <BipoleLeadTable elements={elements} />
                      )}

                      <div
                        style={{
                          paddingTop: "8px",
                          paddingLeft: 0,
                          overflowX: "auto",
                          maxWidth: "95%",
                          border: "1px solid rgb(201, 202, 212)",
                          borderRadius: "6px",
                          marginBottom: "12px",
                        }}
                      >
                        {/* Amplitude table */}
                        <BipoleAmplitudeTable ampArray={ampArray} />
                        <div data-testid="bipoleChart">
                          <table
                            className="table table_datasheet"
                            cellSpacing={5}
                            cellPadding={10}
                            style={{ marginBottom: "0px", height: "405px" }}
                          >
                            <tbody>
                              {elements.map((value: any, index: any) => {
                                if (applyClicked === false) {
                                  return (
                                    <BipoleChartBody
                                      bipoleArray={bipoleArray}
                                      ampArray={ampArray}
                                      index={index}
                                      value={value}
                                      programIndex={programIndex}
                                      key={`bipoleChart-${index}`}
                                    />
                                  );
                                } else if (
                                  applyClicked === true &&
                                  customFilterCheckedArray.includes(index)
                                ) {
                                  return (
                                    <BipoleChartBody
                                      bipoleArray={bipoleArray}
                                      ampArray={ampArray}
                                      index={index}
                                      value={value}
                                      programIndex={programIndex}
                                    />
                                  );
                                } else {
                                  return <tr> </tr>;
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="filterAndLegend">
                        <div className="legend-padding"></div>
                        <BipoleLegend />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="no-data-container">
                  <NoData />
                  <h2>No Data</h2>
                  <p>
                    There is no data for this patient in the selected time period. Please choose a
                    different time period.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`${styles["filter-drawer"]} ${drawerOpen ? styles.open : ""}`}>
          <Button
            type="link"
            className={styles["clear-filters-button"]}
            onClick={() => {
              setProgramIndex(0);
              setLeadFilter(0);
              setMinDate(undefined);
              setMaxDate(undefined);
              setApplyClicked(false);
              setCheckedState(new Array(elements.length).fill(false));
              setCustomFilterFoundIndexArray([]);
              setProgramIndex(0);
              setClearAll(false);
              setLeadValue("All");
              refreshBipoleData({ startDate: null, endDate: null });
              setCustomButtonClicked(false);
            }}
          >
            <ClearFiltersIcon height="15" width="15" className={styles["clear-filters-icon"]} />
            Clear all
          </Button>
          <Button
            className={styles["filter-button"]}
            onClick={handleToggleFilters}
            icon={<FilterIcon className={styles["button-icon"]} />}
            type={drawerOpen ? "text" : undefined}
          >
            Filters
          </Button>

          <div className={styles["filter-drawer-content"]}>
            <Divider />
            <div>
              Analysis Dates
              <RangePicker
                data-testid="date-range-picker"
                defaultValue={[moment(startDateRange), moment(endDateRange)]}
                format={"YYYY-MM-DD"}
                picker="date"
                allowClear={false}
                onChange={onDateChange}
                disabledDate={
                  bipoleArray !== null
                    ? disableDateRanges(moment(minStartDate), moment(maxEndDate))
                    : null
                }
                value={
                  clearAll === false
                    ? [moment(startDateRange), moment(endDateRange)]
                    : [moment(minDate), moment(maxDate)]
                }
              />
            </div>
            <Divider />
            {/* {reportFilters} */}
            <Space direction="vertical">
              <Radio.Group
                style={{ fontWeight: "bold" }}
                options={optionsForLead}
                onChange={onChangeLead}
                value={leadValue}
                optionType="button"
                buttonStyle="solid"
              />
              <Button
                style={{
                  fontWeight: "bold",
                  backgroundColor: customButtonClicked ? "#1890ff" : "white",
                  color: customButtonClicked ? "white" : "black",
                }}
                // className="filterButton"
                onClick={() => {
                  setOpen(true);
                  setCustomButtonClicked(true);
                  setCheckedStateUI(new Array(elements.length).fill(false));
                  setCustomFilterFoundIndexArray([]);
                  setApplyClicked(false);
                }}
              >
                Custom
              </Button>
              <Overlay
                className="overLay"
                open={open}
                onClose={() => {
                  setOpen(false);
                  // setCustomButtonClicked(false);
                }}
                closeOnClick
              >
                <div className="modalView">
                  <h2>Bipole Filter</h2>
                  <h3>Select bipoles to view on the graph</h3>
                  <table>
                    <tbody className="tableModalView">
                      {overLayBipoles.map((element: any, elindex: any) => {
                        //  var splitValues = element.split(',');
                        return (
                          <tr key={`overLayBipoles-${elindex}`}>
                            <td
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto auto",
                                padding: "5px",
                                width: "100px",
                              }}
                            >
                              <input
                                key={`custom-checkbox-${elindex}-${checkedStateUI[elindex]}`}
                                type="checkbox"
                                id={`custom-checkbox-${elindex}`}
                                name={element}
                                value={element}
                                checked={checkedStateUI[elindex]}
                                onChange={() => handleOnChange(elindex, element)}
                              />
                              <label htmlFor={`custom-checkbox-${elindex}`}>{element}</label>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="modalView-buttons">
                    <button
                      className="modalViewButton"
                      onClick={() => handleApplyButtonModalView()}
                      disabled={checkedStateUI.some(e => e === true) ? false : true}
                    >
                      Apply
                    </button>
                    <button
                      className="modalViewButton"
                      onClick={() => {
                        setOpen(false);
                        setCustomButtonClicked(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Overlay>
              Program
              <Select
                placeholder={"Choose a program"}
                style={{
                  width: 160,
                  height: 30,
                }}
                onChange={handleProgramChange}
                value={programIndex !== 0 ? programIndex : null}
              >
                {programsArray.map((progElement: any, progIndex: any) => {
                  return (
                    <Option
                      value={progElement}
                      children={progElement}
                      key={`programs-${progIndex}`}
                    ></Option>
                  );
                })}
              </Select>
            </Space>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
