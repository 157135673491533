/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface TokenModel {
  accessToken?: string;
  refreshToken?: string;
}

export interface NevroToken {
  access_token?: string;
  token_type?: string;
  /** @format int32 */
  expires_in?: number;
}

export interface NevroTokenRequest {
  grant_type: string;
  client_id: string;
  client_secret: string;
}

export interface ReportBipolePatient {
  PatID?: string;
  /** @format date-time */
  RptDt: string;
  RptBipDevs: ReportBipoleDevice[];
}

export type ReportBipoleDevice = Device & {
  VertebalClm: VertebralColumn;
  BipAmpPReliefs: BipoleAmplitudePainRelief[];
};

export interface VertebralColumn {
  LType?: string;
  L1Port?: string;
  L2Port?: string;
  L1PortExt: boolean;
  L2PortExt: boolean;
  TopSet: boolean;
  TopLead?: string;
  /** @format int32 */
  LOnTop: number;
  VHeights?: number[];
  /** @format int32 */
  L1TopV: number;
  /** @format int32 */
  L1TopP: number;
  /** @format int32 */
  L2TopV: number;
  /** @format int32 */
  L2TopP: number;
  /** @format decimal */
  LOff: number;
  SortedBips?: number[][];
}

export interface BipoleAmplitudePainRelief {
  /** @format date-time */
  ThpyStDt: string;
  /** @format date-time */
  AssmtDt?: string;
  /** @format int32 */
  PgNum: number;
  PgName?: string;
  BipIdx?: number[];
  /** @format decimal */
  Amp: number;
  /** @format double */
  OPainRelief?: number;
  /** @format duration */
  Dur: string;
  IsMA: boolean;
  IsPD: boolean;
  IsFav: boolean;
  ImpFunc: ActivityChangeType;
  MedLev: EffectiveType;
  PScores: PainScore[];
}

export enum ActivityChangeType {
  Undefined = 0,
  Increase = 1,
  Decrease = 2,
  Same = 3,
  NA = 4,
}

export enum EffectiveType {
  Undefined = 0,
  Least = 1,
  Decrease = 2,
  NoChange = 3,
  Increase = 4,
  Most = 5,
  NotApplicable = 6,
}

export interface PainScore {
  PReg?: string;
  /** @format float */
  Score: number;
}

export interface Device {
  DevModel?: string;
  DevTyp?: string;
  DevSerial?: string;
  /** @format date-time */
  DevAsgDt?: string;
  /** @format int64 */
  DtRngMinTs: number;
  /** @format int64 */
  DtRngMaxTs: number;
  /** @format int64 */
  DtRngStTs: number;
  /** @format int64 */
  DtRngEndTs: number;
}

export interface ReportImpedancePatient {
  PatID?: string;
  /** @format date-time */
  RptDt: string;
  RptImpDevs: ReportImpedanceDevice[];
}

export type ReportImpedanceDevice = Device & {
  IE01DPs?: DataPoint[];
  IE02DPs?: DataPoint[];
  IE03DPs?: DataPoint[];
  IE04DPs?: DataPoint[];
  IE05DPs?: DataPoint[];
  IE06DPs?: DataPoint[];
  IE07DPs?: DataPoint[];
  IE08DPs?: DataPoint[];
  IE09DPs?: DataPoint[];
  IE10DPs?: DataPoint[];
  IE11DPs?: DataPoint[];
  IE12DPs?: DataPoint[];
  IE13DPs?: DataPoint[];
  IE14DPs?: DataPoint[];
  IE15DPs?: DataPoint[];
  IE16DPs?: DataPoint[];
  MRIEnterDPs: DataPoint[];
  MRIExitDPs: DataPoint[];
  PgmElctrds?: ProgramElectrodes[];
  ElctrdNames?: string[];
};

export interface DataPoint {
  /** @format int64 */
  Ts: number;
  /** @format decimal */
  Val: number;
}

export interface ProgramElectrodes {
  /** @format int32 */
  PgNum: number;
  PgName?: string;
  ENums?: number[];
}

export interface ReportProgramPatient {
  PatID?: string;
  /** @format date-time */
  RptDt: string;
  RptPrgDevs: ReportProgramDevice[];
}

export type ReportProgramDevice = Device & {
  PgDPs: DataPointProgram[];
  A1AmpDPs: DataPoint[];
  A2AmpDPs: DataPoint[];
  A3AmpDPs: DataPoint[];
  A4AmpDPs: DataPoint[];
  OverallPReliefDPs: DataPoint[];
  InWinDPs: DataPointThpyAssess[];
  OutWinDPs: DataPointThpyAssess[];
  HfxDPs: DataPointThpyAssess[];
  PgmNames: string[];
  FollowUpAssessments: FollowUpAssessment[];
  ActiveTherapyPrograms: ActiveTherapyProgram[];
  /** @format duration */
  EvalWinStTime: string;
  /** @format duration */
  EvalWinEndTime: string;
};

export type DataPointProgram = DataPoint & {
  /** @format int32 */
  OriPgmNum: number;
  OriPgmName: string;
};

export type DataPointThpyAssess = DataPoint & {
  /** @format int64 */
  ThpyTs: number;
};

export interface FollowUpAssessment {
  /** @format int64 */
  Ts: number;
  /** @format date-time */
  Dt: string;
  AssmtAppSrcType: AssessmentAppSourceType;
  IsInWindows?: boolean;
  /** @format float */
  OPainRelief?: number;
  ImpFunc: ActivityChangeType;
  MedLev: EffectiveType;
  SleepImp: SleepImprovementType;
  PScores?: PainScore[];
  Recom: string;
  UserAct: string;
}

export enum AssessmentAppSourceType {
  Undefined = 0,
  Programmer = 1,
  PatientApplication = 2,
  NevroCloud = 3,
  HF10 = 4,
}

export enum SleepImprovementType {
  Undefined = 0,
  Increase = 1,
  Decrease = 2,
  Same = 3,
}

export interface ActiveTherapyProgram {
  /** @format int64 */
  Ts: number;
  /** @format date-time */
  ThpyDt: string;
  IsThpyOn: boolean;
  /** @format duration */
  Dur: string;
  ThpyPgm?: TherapyProgram;
  FUAssmt?: FollowUpAssessment[];
  IsPFIO: boolean;
  /** @format decimal */
  iqAlgorithmVersion?: number;
}

export interface TherapyProgram {
  /** @format int32 */
  Idx: number;
  Name?: string;
  ThpyAreas?: TherapyArea[];
  /** @format decimal */
  OffTime?: number;
  /** @format decimal */
  TotalOnTime?: number;
}

export interface TherapyArea {
  /** @format int32 */
  Idx: number;
  IsEnabled: boolean;
  /** @format decimal */
  Freq: number;
  /** @format decimal */
  OnTime: number;
  /** @format decimal */
  PW: number;
  /** @format decimal */
  StepSz: number;
  /** @format decimal */
  AmpMin: number;
  /** @format decimal */
  AmpMax: number;
  /** @format decimal */
  AmpSt: number;
  /** @format decimal */
  AmpCurrent: number;
  Elctrds?: Electrode[];
}

export interface Electrode {
  /** @format int32 */
  Idx: number;
  Pol?: string;
}

export interface ReportTherapyAssessmentPatient {
  PatID?: string;
  /** @format date-time */
  RptDt: string;
  RptThpyAssmtDevs: ReportTherapyAssessmentDevice[];
}

export type ReportTherapyAssessmentDevice = Device & {
  ActThpyPgms: ActiveTherapyProgram[];
};

export interface ReportTherapyPatient {
  PatID?: string;
  /** @format date-time */
  RptDt: string;
  RptThpyDevs: ReportTherapyDevice[];
}

export type ReportTherapyDevice = Device & {
  LowBatDPs: DataPoint[];
  ThpyOffDPs: DataPoint[];
  OverallPReliefDPs: DataPoint[];
  InWinDPs: DataPointThpyAssess[];
  OutWinDPs: DataPointThpyAssess[];
  HfxDPs: DataPointThpyAssess[];
  PRegionsDPs: DataPoint[][];
  PRegions: string[];
  /** @format duration */
  EvalWinStTime: string;
  /** @format duration */
  EvalWinEndTime: string;
  FollowUpAssessments: FollowUpAssessment[];
  ActiveTherapyPrograms: ActiveTherapyProgram[];
};

export interface Patient {
  /** @format guid */
  PEid?: string;
  NevroPId?: string;
  Region?: string;
  Clinic?: string;
  ImpPhysician?: string;
  FollowupPhysician?: string;
  Stims: Stimulator[];
  PatientApps?: PatientApp[];
}

export interface Stimulator {
  /** @format guid */
  StimEid: string;
  DevModel?: string;
  DevTyp?: string;
  DevSerial?: string;
  /** @format date-time */
  DevAsgDt?: string;
  FirmwareVersion?: string;
  InterfaceVersion?: string;
  VertebalClm?: VertebralColumn;
  /** @format date-time */
  LastSyncDt: string;
  TherapyMode: TherapyMode;
  InMRIMode: boolean;
  IsiQModeReady: boolean;
  IsManualModeReady: boolean;
  InTherapy: boolean;
}

export enum TherapyMode {
  Manual = 0,
  IQ = 1,
}

export interface PatientApp {
  PAppEid?: string;
  AppVer?: string;
  Os: MobileTechOS;
  IsPushNotificationReady: boolean;
  IsPairedToStim: boolean;
  StimulatorEid?: string;
}

export enum MobileTechOS {
  Android = 0,
  IOS = 1,
}
