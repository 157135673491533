import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { loadTherapyData, selectTherapyReport } from "../../redux/therapySlice";
import { ErrorComponent } from "../Error";
import { Loading } from "../Loading";
import { DateRange } from "../Report/ReportContainer";
import { TherapyReport } from "./TherapyReport";

export const TherapyReportContainer: React.FC = () => {
  const selectedDevice = useAppSelector(state => state.patient.selectedDevice);

  const { loading, therapyData, error } = useSelector(selectTherapyReport);
  const dispatch = useAppDispatch();
  const refreshTherapyData = useCallback(
    async (startDate: Date = null, endDate: Date = null) => {
      dispatch(loadTherapyData({ startDate, endDate }));
    },
    [dispatch]
  );
  const reportDate = new Date(therapyData?.RptDt);
  const deviceTherapyData = therapyData?.RptThpyDevs?.find(
    dev => dev.DevTyp === selectedDevice.deviceType && dev.DevSerial === selectedDevice.serialNumber
  );
  const dateRange = {
    startDate: new Date(deviceTherapyData?.DtRngStTs),
    endDate: new Date(deviceTherapyData?.DtRngEndTs),
  };
  const dateRangeLimits = {
    startDate: new Date(deviceTherapyData?.DtRngMinTs),
    endDate: new Date(deviceTherapyData?.DtRngMaxTs),
  };

  // Reload the data when the selected device changes
  useEffect(() => {
    refreshTherapyData();
  }, [refreshTherapyData, selectedDevice]);

  const handleDateRangeChanged = (newDateRange: DateRange) => {
    if (newDateRange.startDate === null && newDateRange.endDate === null) {
      refreshTherapyData();
    } else {
      refreshTherapyData(newDateRange.startDate, newDateRange.endDate);
    }
  };

  const handleRefreshTherapyData = useCallback(() => {
    refreshTherapyData(dateRange.startDate, dateRange.endDate);
  }, [dateRange.endDate, dateRange.startDate, refreshTherapyData]);

  if (!selectedDevice) {
    return <div>Please select a device to view the therapy report.</div>;
  } else if (error) {
    return <ErrorComponent tokenExpired={error.type === "token expired"} message={error.message} />;
  } else if (!deviceTherapyData) {
    return <Loading />;
  } else {
    return (
      <TherapyReport
        loading={loading}
        reportDate={reportDate}
        deviceTherapyData={deviceTherapyData}
        refreshReportData={handleRefreshTherapyData}
        dateRange={dateRange}
        dateRangeLimits={dateRangeLimits}
        onDateRangeChanged={handleDateRangeChanged}
      />
    );
  }
};
