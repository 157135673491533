/** Returns the item with the largest number returned from a selector function.
 * Example: max([{ a: 1 }, { a: 2 }], n => n.a) returns { a: 2 }.
 */
export const max = <T>(arr: Array<T>, fn: (a: T) => number) => {
    if (!arr?.length) {
        return null;
    }
    return arr.reduce((prev, curr) => fn(prev) < fn(curr) ? curr : prev);
}
  
