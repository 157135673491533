import { useEffect, useCallback, useState } from "react";
import { apiInstance } from "../../api/api-instance";
import { ReportTherapyAssessmentPatient } from "../../api/data-contracts";
import { useAppSelector } from "../../redux/hooks";
import { ApiError } from "../../redux/patientSlice";
import { ErrorComponent } from "../Error";
import { Loading } from "../Loading";
import { Relaunch } from "../Relaunch";
import { DateRange } from "../Report/ReportContainer";
import { ProgramDetailsReport } from "./ProgramDetailsReport";

export const ProgramDetailsReportContainer: React.FC = () => {
  const selectedDevice = useAppSelector(state => state.patient.selectedDevice);
  const [programDetails, setProgramDetails] = useState<ReportTherapyAssessmentPatient>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();
  const loadProgramDetails = useCallback(
    async (startDate: Date = null, endDate: Date = null) => {
      setLoading(true);
      setError(undefined);
      try {
        const data = await apiInstance.reportTherapyAssmtGetTherapyAssessmentReport("1", {
          StimulatorEid: selectedDevice.stimEid,
          StartDate: startDate?.toISOString(),
          EndDate: endDate?.toISOString(),
        });
        setProgramDetails(data);
      } catch (error: any) {
        if (error.status === 401) {
          setError({ type: "token expired", message: "Token Expired" });
        } else {
          setError({ type: "error", message: error.error?.Message || error.message });
        }
      }
      setLoading(false);
    },
    [selectedDevice.stimEid]
  );

  const reportDate = new Date(programDetails?.RptDt);
  const deviceProgramDetails = programDetails?.RptThpyAssmtDevs?.find(
    dev => dev.DevTyp === selectedDevice.deviceType && dev.DevSerial === selectedDevice.serialNumber
  );
  const dateRange = {
    startDate: new Date(deviceProgramDetails?.DtRngStTs),
    endDate: new Date(deviceProgramDetails?.DtRngEndTs),
  };

  const dateRangeLimits = {
    startDate: new Date(deviceProgramDetails?.DtRngMinTs),
    endDate: new Date(deviceProgramDetails?.DtRngMaxTs),
  };

  // Reload the data when the selected device changes
  useEffect(() => {
    loadProgramDetails();
  }, [loadProgramDetails]);

  const handleDateRangeChanged = (newDateRange: DateRange) => {
    if (newDateRange.startDate === null && newDateRange.endDate === null) {
      loadProgramDetails();
    } else {
      loadProgramDetails(newDateRange.startDate, newDateRange.endDate);
    }
  };
  const handleRefreshImpedanceData = useCallback(() => {
    loadProgramDetails(dateRange.startDate, dateRange.endDate);
  }, [dateRange.endDate, dateRange.startDate, loadProgramDetails]);

  if (!selectedDevice) {
    return <div>Please select a device to view the impedance report.</div>;
  } else if (error && error.type === "token expired") {
    return <Relaunch />;
  } else if (error) {
    return <ErrorComponent tokenExpired={error.type === "token expired"} message={error.message} />;
  } else if (!deviceProgramDetails) {
    return <Loading />;
  } else {
    return (
      <ProgramDetailsReport
        loading={loading}
        reportDate={reportDate}
        deviceProgramDetails={deviceProgramDetails}
        refreshReportData={handleRefreshImpedanceData}
        dateRange={dateRange}
        dateRangeLimits={dateRangeLimits}
        onDateRangeChanged={handleDateRangeChanged}
      />
    );
  }
};
