import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { apiInstance } from "../api/api-instance";
import { ReportImpedancePatient } from "../api/data-contracts";
import moment from "moment";
import { ApiError } from "./patientSlice";

interface ImpedanceState {
  impedanceData?: ReportImpedancePatient;
  loading: boolean;
  error?: ApiError;
}

// Define the initial state using that type
const initialState: ImpedanceState = {
  loading: false,
};

export const loadImpedanceData = createAsyncThunk<
  ReportImpedancePatient,
  { startDate: Date; endDate: Date },
  { state: RootState }
>(
  "impedance/loadImpedanceData",
  async ({ startDate = null, endDate = null }, { getState, rejectWithValue }) => {
    const {
      patient: { selectedDevice },
    } = getState();
    if (selectedDevice?.stimEid) {
      if (null != startDate && null != endDate) {
        // startDate: always set to start of day
        startDate = moment(startDate).startOf("day").toDate();
        // endDate:
        // If it is Today, set current date
        // If it is Not Today, set end of day.
        var isCurrentDate = moment(endDate).isSame(new Date(), "day");
        if (isCurrentDate) {
          endDate = new Date();
        } else {
          endDate = moment(endDate).endOf("day").toDate();
        }
      }

      try {
        return await apiInstance.reportImpedanceGetImpedanceReportValues("1", {
          StimulatorEid: selectedDevice.stimEid,
          // send in UTC DateTime
          StartDate: startDate?.toISOString(),
          EndDate: endDate?.toISOString(),
        });
      } catch (error: any) {
        if (error.status === 401) {
          return rejectWithValue({ type: "token expired", message: "Token Expired" });
        } else {
          // Get error message from response if available
          const message = error.error?.Message || error.message;
          return rejectWithValue({ type: "error", message });
        }
      }
    }
  }
);

export const impedanceSlice = createSlice({
  name: "impedance",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadImpedanceData.pending, state => {
      //For now leave the data intact so we don't clear the screen when updating filters.
      //state.impedanceData = null;
      state.loading = true;
    });
    builder.addCase(loadImpedanceData.fulfilled, (state, action) => {
      state.loading = false;
      state.impedanceData = action.payload;
    });
    builder.addCase(loadImpedanceData.rejected, (_, action) => {
      const error = action.payload as ApiError;
      return {
        ...initialState,
        error,
      };
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const selectImpedanceReport = (state: RootState) => state.impedanceReport;
