import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { apiInstance } from "../api/api-instance";
import type { RootState } from "./store";

interface Config {
  apiBaseUrl?: string;
  datadogRum?: {
    applicationId: string;
    clientToken: string;
    env: string;
    version: string;
    sessionReplaySampleRate: number;
  }
}

interface ConfigState extends Config {
  accessToken?: string;
  error: boolean;
}

// Define the initial state using that type
const initialState: ConfigState = {
  error: false,
};

/** Loads the config and updates the global apiInstance's baseUrl. */
export const loadConfig = createAsyncThunk("config/loadConfig", async () => {
  const response = await fetch("/config.json");
  const config = await response.json();
  // Update the global apiInstance base url.
  apiInstance.baseUrl = config.apiBaseUrl;
  return config as Config;
});

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadConfig.pending, (state) => {
      state.error = false;
    })
    builder.addCase(loadConfig.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    });
    builder.addCase(loadConfig.rejected, (state) => {
      state.error = true;
    })
  },
});

// Other code such as selectors can use the imported `RootState` type
export const selectConfig = (state: RootState) => state.config;

export const useConfig = () => {
  const config = useSelector(selectConfig);
  return config;
}
