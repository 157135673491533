import React from "react";
import { useAppSelector } from "../../redux/hooks";

const Patient: React.FC = () => {
  const patient = useAppSelector(store => store.patient);
  return (
    <div>
      <h2>Patient Home Page</h2>
      <div>Patient Id: {patient?.patientId}</div>
      <div>Devices:</div>
      <table>
        <tbody>
        <tr>
          <th>Type</th>
          <th>Serial</th>
          <th>Implanted</th>
        </tr>
        {patient.devices.map(device => (
          <tr key={device.stimEid}>
            <td>{device.deviceType}</td>
            <td>{device.serialNumber}</td>
            <td>{new Date(device.assignedDate).toDateString()}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default Patient;
