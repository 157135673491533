import { Layout as AntdLayout } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import NavMenu from "./NavMenu";
import styles from "./Layout.module.scss";

export const Layout = (props: any) => {
  return (
    <AntdLayout hasSider>
      <Sider width={160}>
        <NavMenu />
      </Sider>
      <Content className={styles.content}>{props.children}</Content>
    </AntdLayout>
  );
};
