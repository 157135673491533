import React from "react";
import Heart from "../images/Heart.png";
import styles from "./BipoleLegend.module.scss";

export const BipoleLegend: React.FC = () => {
  return (
    <>
      <div className={styles["legend-container"]}>
        <div>
          <strong className={styles["legend-heading"]}>Legend</strong>
        </div>
        <div className={styles["legend-items-container"]}>
          <div>
            <div>
              <div className={`${styles["pill-container"]} ${styles.blue}`}></div>
              <strong>&gt; 50% Relief</strong>
            </div>
            <div>
              <div className={`${styles["pill-container"]} ${styles.green}`}></div>
              <strong>30 - 50% Relief</strong>
            </div>
            <div>
              <div className={`${styles["pill-container"]} ${styles.yellow}`}></div>
              <strong>&lt; 30% Relief</strong>
            </div>
          </div>
          <div>
            <div>
              <div className={`${styles["pill-container"]} ${styles.duration}`}></div>
              <strong>Tested &gt; 48 hrs</strong>
            </div>
            <div>
              <div className={styles["pill-container"]}>
                <div className={`${styles.duration} ${styles.fifty}`}></div>
              </div>
              <strong>Tested 24 - 48 hrs</strong>
            </div>
            <div>
              <div className={styles["pill-container"]}>
                <div className={`${styles.duration} ${styles.twentyfive}`}></div>
              </div>
              <strong>Tested &lt; 24 hrs</strong>
            </div>
          </div>
          <div>
            <div>
              <div className={styles["pill-container"]}></div>
              <strong>No Data</strong>
            </div>
            <div>
              <div className={styles["pill-container"]}>
                <img
                  className={styles.heart}
                  src={Heart}
                  height="10"
                  alt="Heart"
                />
              </div>
              <strong>Favorites</strong>
            </div>
          </div>
          <div>
            <div>
            <div className={`${styles["pill-container"]} ${styles.gradient}`}></div>
              <strong>Multi-Area</strong>
            </div>
            <div>
            <div className={`${styles["pill-container"]} ${styles.duration} ${styles["radial-gradient"]}`}></div>
              <strong>Pulse Dosing</strong>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
