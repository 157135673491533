import { useMemo } from "react";
import { ActiveTherapyProgram, DataPoint } from "./api/data-contracts";

/** Returns the initial iq version seen by the report and a distinct list of upgrade events when we see the iq version change */
export const useIqUpgradeDataPoints = (
  activeTherapyPrograms: Array<Pick<ActiveTherapyProgram, "Ts" | "iqAlgorithmVersion">>
) => {
  // Remove the first datapoint since we are only interested in upgrade events (i.e. v1 -> v2)
  const iqDataPoints = useMemo(
    () =>
      activeTherapyPrograms
        .filter(at => at.iqAlgorithmVersion)
        .map(at => ({
          Ts: at.Ts,
          Val: at.iqAlgorithmVersion,
        })),
    [activeTherapyPrograms]
  );
  const initialIqDataPoint = useMemo(() => iqDataPoints[0], [iqDataPoints]);
  /** The upgrade events where we first see a new version of the iq algorithm */
  const iqUpgradeDataPoints = useMemo(
    () =>
      Object.values(
        iqDataPoints.reduce((prev, cur) => {
          if (!prev[cur.Val]) {
            prev[cur.Val] = cur;
          }
          return prev;
        }, {} as { [key: number]: DataPoint })
      ).slice(1),
    [iqDataPoints]
  );

  return {
    initialIqDataPoint,
    iqUpgradeDataPoints,
  };
};
